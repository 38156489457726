import React, { Fragment, useContext, useState, useEffect } from 'react';

import { AppContext } from 'AppContext';
import { useUrlQuery } from 'utils/hooks';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';

import { CONFIGURATION_TABS } from './consts';
import { ConfigurationHeader } from './ConfigurationHeader';
import { MembersTab } from './Tabs/MembersTab';
import { ProductsTab } from './Tabs/ProductsTab';
import { GeneralLedgerTab } from './Tabs/GeneralLedgerTab';
import { ExternalSourcesTab } from './Tabs/ExternalSourcesTab';
import { GeneralSettingsTab } from './Tabs/GeneralSettingsTab';
import { SubscriptBillingTab } from './Tabs/SubscriptBillingTab';
import { ExchangeRatesTab } from './Tabs/ExchangeRatesTab';

const Configuration = () => {
  const query = useUrlQuery();
  const currentTab = query.get('tab');
  const integrationId = parseInt(query.get('integration'));

  const { organizations, integrations } = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [tab, setTab] = useState(CONFIGURATION_TABS.PRODUCTS);

  useEffect(() => {
    currentTab && setTab(currentTab);
  }, [currentTab]);

  const CRMIntegrations = [];
  let selectedIntegration = {};
  for (const integration of integrations || []) {
    if (currentTab !== CONFIGURATION_TABS.SOURCES) continue;

    const { type, id } = integration;
    if (type === INTEGRATION_TYPES.CRM) CRMIntegrations.push(integration);
    // If no id was passed when navigation to this tab, select one
    if (!integrationId || id === integrationId) selectedIntegration = integration;
  }

  const getActiveTab = () => {
    switch (tab) {
      case CONFIGURATION_TABS.MEMBERS:
        return <MembersTab />;
      case CONFIGURATION_TABS.EXCHANGE_RATES:
        return <ExchangeRatesTab />;
      case CONFIGURATION_TABS.PRODUCTS:
        return <ProductsTab />;
      case CONFIGURATION_TABS.GENERAL_SETTINGS:
        return <GeneralSettingsTab />;
      case CONFIGURATION_TABS.SUBSCRIPT_BILLING:
        return <SubscriptBillingTab />;
      case CONFIGURATION_TABS.SOURCES:
        switch (selectedIntegration.type) {
          case INTEGRATION_TYPES.CRM:
            return (
              <ExternalSourcesTab
                configSelectedIntegration={selectedIntegration}
                selectedIntegration={selectedIntegration}
              />
            );
          case INTEGRATION_TYPES.GL:
            return (
              <GeneralLedgerTab
                isLoading={isLoading}
                selectedService={getServiceCategory(selectedIntegration?.service)}
                selectedIntegration={selectedIntegration}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [organizations, integrations]);

  return (
    <Fragment>
      {/* TODO: remove this when all non-source tabs import ConfigurationHeader themselves */}
      {tab !== CONFIGURATION_TABS.SOURCES && (
        <ConfigurationHeader activeTab={tab} selectedIntegration={selectedIntegration} />
      )}
      {getActiveTab()}
    </Fragment>
  );
};

export { Configuration };
