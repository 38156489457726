import React from 'react';
import { useParams } from 'react-router';
import { useMVP } from 'utils/hooks';
import { ContractSingleContent } from './ContractSingleContent';

export const ContractSinglePage = () => {
  const { contractId } = useParams();

  const isMVP = useMVP();

  if (!isMVP) return null;

  return <ContractSingleContent contractId={contractId} />;
};
