import React, { useState, useContext, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { getRequiredPlugins } from 'models/appSettings/plugins';
import { hasConfirmedTransactions as organizationHasConfirmedTransactions } from 'models/organization';
import { checkIntegrationStatus } from 'models/integration';
import { getUrlParams } from 'utils/urlUtils';
import { PAGE_CONTEXT_VALUES, usePageContext, useStateWithStorage } from 'utils/hooks';
import { DEFAULT_STORAGE_VALUE } from 'utils/hooks/usePageContext';
import { RATES_TYPE } from 'consts/global';
import { useMonthlyBreakdownAPI } from 'api/monthlyBreakdown';
import { DashboardContext } from './DashboardContext';
import { useCardChartModal } from './Common/Card';
import { useReportAPI } from 'api/reports/hooks';
import { COMMON_METRICS_DASHBOARD_ID } from './ReportBuilder/consts';
import { getCommonMetricsReport } from './utils';

export const DashboardProvider = ({ children, reportId, report, ...rest }) => {
  const { organizations, integrations, appSettings, requiredPlugins, setRequiredPlugins } = useContext(AppContext);
  const { openModal: openCardChartModal, Modal: CardChartModal } = useCardChartModal();
  // We should store it in reports in the future, for now use this default
  const [growthType, setGrowthType] = useStateWithStorage('dashboard-growth-type', DEFAULT_STORAGE_VALUE.growthType);
  const [dataState, setDataState] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [pluginsToLoad, setPluginsToLoad] = useState(null);

  const { urlState } = usePageContext({
    page: PAGE_CONTEXT_VALUES.snapshot.key,
    includeInUrl: {
      growthType: [growthType, setGrowthType],
    },
  });

  const {
    dataFilter,
    metadataFilter,
    rollup,
    exchangeRatesType,
    exchangeRatesDate,
    optimisticAnalytics,
    isCommitted,
    countInfluxAsRenewed,
  } = urlState;

  const currentOrganization = useMemo(() => organizations?.[0], [organizations]);
  const hasIncompleteIntegration = checkIntegrationStatus({ integrations });
  const hasConfirmedTransactions = organizationHasConfirmedTransactions({ organization: currentOrganization });

  const { data: reportData, isLoading: reportIsLoading, error: reportError } = useReportAPI({
    orgId: currentOrganization?.id,
    reportId,
    // We only fetch the report if we don't have it yet (example: when refreshing the page)
    autoFetch: reportId && !report && ![COMMON_METRICS_DASHBOARD_ID].includes(reportId),
  });

  const finalReportData = useMemo(
    () =>
      reportId === COMMON_METRICS_DASHBOARD_ID
        ? getCommonMetricsReport({ ...appSettings, dataFilter, metadataFilter })
        : reportIsLoading
        ? null
        : report ??
          reportData ??
          currentOrganization?.defaultDashboard ??
          getCommonMetricsReport({ ...appSettings, dataFilter, metadataFilter }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportId, reportIsLoading, report, reportData, currentOrganization],
  );

  useEffect(() => {
    if (finalReportData) {
      const plugins = getRequiredPlugins({ reportData: finalReportData });

      setRequiredPlugins(plugins);
      setPluginsToLoad(plugins);
    }
  }, [finalReportData, setRequiredPlugins]);

  const mergedPluginsToLoad = [...new Set([...(requiredPlugins ?? []), ...(pluginsToLoad ?? [])])];

  const { referral } = getUrlParams();
  const { data, isLoading, error, refetchMonthlyBreakdown } = useMonthlyBreakdownAPI({
    orgId: currentOrganization?.id,
    startMonth: dayjs(dataFilter.startMonth).month(),
    startYear: dayjs(dataFilter.startMonth).year(),
    endMonth: dayjs(dataFilter.endMonth).month(),
    endYear: dayjs(dataFilter.endMonth).year(),
    metadataFilter,
    rollup,
    plugins: mergedPluginsToLoad,
    optimisticAnalytics,
    isCommitted,
    growthType,
    countInfluxAsRenewed,
    includeLastTransactionUpdatedAt: true,
    hasCostAttribution: currentOrganization?.hasCostAttribution,
    referral,
    segmentBy: null,
    usesMetricsEngine: currentOrganization?.usesMetricsEngine,
    ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
    ratesDate: exchangeRatesDate,
    autoFetch: !!pluginsToLoad,
  });

  useEffect(() => {
    if (!data) return;
    const {
      revenueStats,
      seatStats,
      customerStats,
      costStats,
      breakdowns,
      saasStats,
      summaryStats,
      lastTransactionUpdatedAt,
    } = data;
    setDataState({
      revenueNURDCStats: revenueStats,
      seatNURDCStats: seatStats,
      customerNURDCStats: customerStats,
      costStats,
      segmentBreakdowns: breakdowns,
      saasStats,
      summaryStats,
      lastTransactionUpdatedAt,
      ...data.options,
    });
    setIsFirstLoad(false);
  }, [data]);

  const uiState = {
    openCardChartModal,
    error: error || reportError,
    isLoading: isLoading || !data || reportIsLoading,
    dataState,
    data,
    refetchMonthlyBreakdown,
    isFirstLoad,
    hasIntegrations: integrations.length > 0,
    hasIncompleteIntegration,
    hasConfirmedTransactions,
    hasUnconfirmedTransactions: data?.hasUnconfirmedTransactions,
    CardChartModal,
    report: finalReportData,
  };

  return (
    <DashboardContext.Provider
      value={{
        refetchMonthlyBreakdown,
        ...dataState,
        ...urlState,
        ...uiState,
        ...rest,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
