import dayjs from 'dayjs';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { TransactionRecognitionIcon } from 'shared/Icons';
import { RowActionPopover } from 'components/Table';

const TransactionName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

const Value = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

export const getColumns = ({ onRemoveTransaction }) => {
  const columns = [
    {
      Header: 'TRANSACTION NAME',
      accessor: 'name',
      id: 'name',
      width: 200,
      Cell: ({ value, row }) => (
        <TransactionName onClick={() => window.open(`/transactions/${row.original.id}`, '_blank')}>
          {value || '[No name]'}
        </TransactionName>
      ),
    },
    {
      Header: 'PRODUCT',
      accessor: 'product_name',
      id: 'product_name',
      width: 150,
      Cell: ({ value }) => <Value>{value || '[No Product]'}</Value>,
    },
    {
      Header: 'START',
      accessor: 'start_date',
      id: 'start_date',
      width: 100,
      Cell: ({ value }) => <Value>{dayjs.utc(value).format('MMM D, YYYY')}</Value>,
    },
    {
      Header: 'END',
      accessor: 'end_date',
      id: 'end_date',
      width: 100,
      Cell: ({ value }) => <Value>{value ? dayjs.utc(value).format('MMM D, YYYY') : 'No end date'}</Value>,
    },
    {
      Header: 'TYPE',
      accessor: 'recognition',
      id: 'recognition',
      width: 50,
      Cell: ({ value }) => <TransactionRecognitionIcon type={value} />,
    },
    {
      Header: 'TOTAL AMOUNT',
      accessor: 'recurring_amount',
      id: 'recurring_amount',
      width: 150,
      alignRight: true,
      Cell: ({ value, row }) => (
        <Value>
          {numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: row.original.currency,
          })}
        </Value>
      ),
    },
    {
      Header: 'TOTAL AMOUNT',
      accessor: 'amount',
      id: 'amount',
      width: 150,
      alignRight: true,
      Cell: ({ value, row }) => (
        <Value>
          {numberFormatter({
            rawValue: value,
            type: NUMBER_FORMATS.CURRENCY,
            currency: row.original.currency,
          })}
        </Value>
      ),
    },
    {
      Header: '',
      id: 'actions',
      width: 50,
      Cell: ({ row }) => (
        <RowActionPopover
          onDeleteClick={() => onRemoveTransaction({ transactionId: row.original.id })}
          deleteText="Remove"
        />
      ),
    },
  ];

  return columns;
};
