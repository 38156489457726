import { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { Column, Flexer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { ReactComponent as BgImage } from 'images/save-schedule-settings-bg.svg';
import { AppContext } from 'AppContext';
import { useReceiptAPI } from 'api/billingReceipts';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';

import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { SUPPORTED_INVOICE_LANGUAGES } from '../../../consts';
import { buildInvoicesFromChangedDraftInvoice } from '../../utils';

const SettingsContainer = styled(Column)`
  background-color: white;
  border: 1px solid var(--primaryBlack5);
  margin-block: 10px;
  padding: 12px;
  border-radius: 12px;
  gap: 8px;
  align-items: flex-start;
  position: relative;
`;

const FadedText = styled.div`
  font-size: 14px;
  color: var(--primaryBlack50);
`;

const BoldedText = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const StyledBgImage = styled(BgImage)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 12px;
`;

const MainText = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
  padding: 10px;
`;

const SaveSettingsModal = ({
  onClose,
  showCurrency,
  hasInvoiceTemplates,
  autoSendInvoicesValue,
  autoSendReceiptValue,
  currencyValue,
  afterConfirmFn,
  languageValue,
  autoChargeValue,
  externalInvoiceTemplateValue,
  receipts,
  externalInvoiceTemplateOptions,
  initialEntityId,
}) => {
  const { orgId, entities, organizations } = useContext(AppContext);

  const {
    scheduleFormRef,
    currentInvoicingSchedule,
    invoicingScheduleFormValues,
    isScheduleDraft,
    invoicingServiceDisplayName,
    invoiceFormValues,
    fillScheduleWithDraftInvoices,
  } = useContext(InvoicingScheduleContext);

  const {
    operations: { bulkUpdateReceipts },
  } = useReceiptAPI({
    orgId,
    autoFetch: false,
  });

  const { data: templates = [] } = useInvoiceMemoTemplatesAPI({ orgId, autoFetch: isScheduleDraft });

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const selectedEntity = entities?.find((entity) => entity.id === invoicingScheduleFormValues.entity_id);

  const handleConfirm = async () => {
    if (!isScheduleDraft) {
      scheduleFormRef?.current?.submitForm();
    }

    if (currentInvoicingSchedule?.auto_send_receipts !== autoSendReceiptValue) {
      const receiptsToUpdate = receipts?.filter((receipt) => !receipt.sent_at)?.map((receipt) => receipt.id) ?? [];
      receiptsToUpdate.length !== 0 &&
        bulkUpdateReceipts({
          data: {
            auto_send: autoSendReceiptValue,
          },
          filters: {
            receiptIds: receiptsToUpdate,
          },
        });
    }

    if (isScheduleDraft && initialEntityId !== invoicingScheduleFormValues?.entity_id && setFieldValue) {
      const selectedFooterTemplate = templates.find(
        (template) => template.id === selectedEntity?.footer_memo_template_id,
      );
      const selectedMemoTemplate = templates.find((template) => template.id === selectedEntity?.memo_template_id);

      const changedData = {
        invoice_memo_template_id: selectedEntity?.memo_template_id ?? null,
        memo: selectedMemoTemplate?.content || '',
        invoice_secondary_memo_template_id: selectedEntity?.footer_memo_template_id ?? null,
        secondary_memo: selectedFooterTemplate?.content || '',
        entity_id: selectedEntity?.id ?? null,
      };

      await fillScheduleWithDraftInvoices({
        invoices: buildInvoicesFromChangedDraftInvoice({
          invoices: invoicingScheduleFormValues?.invoices ?? [],
          applyToAll: true,
          invoiceChanges: changedData,
          savedInvoice: { ...invoiceFormValues, ...changedData },
        }),
      });
    }

    afterConfirmFn();
  };

  return (
    <ModalContainer data-cy="invoice__settings__save-settings">
      <Modal maxWidth="500px" height="auto" overflow="visible">
        <ModalHeader>
          <ModalCloseIcon onClick={onClose} />
          <ModalTitle compact margin=" 0px 0px 0px 30px">
            <b>Confirm new settings</b>
          </ModalTitle>
        </ModalHeader>

        <ModalBody compact>
          <SettingsContainer>
            <Flexer gap="4px">
              <FadedText>Sender organization:</FadedText>
              <BoldedText data-cy="invoice__settings__entity-display-name">
                {selectedEntity
                  ? `${selectedEntity.entity_name ? `${selectedEntity.entity_name}: ` : ''}${
                      selectedEntity.company_name
                    }`
                  : organizations[0]?.name}
              </BoldedText>
            </Flexer>

            <Flexer gap="4px">
              <FadedText>Linked general ledger:</FadedText>
              <BoldedText>{!!invoicingServiceDisplayName ?? 'None'}</BoldedText>
            </Flexer>

            {!!hasInvoiceTemplates && (
              <Flexer gap="4px">
                <FadedText>Template theme:</FadedText>
                <BoldedText>
                  {externalInvoiceTemplateOptions?.find((option) => option.value === externalInvoiceTemplateValue)
                    ?.label ?? 'None'}
                </BoldedText>
              </Flexer>
            )}
            {!!showCurrency && (
              <Flexer gap="4px">
                <FadedText>Currency:</FadedText>
                <BoldedText>{`${ISO_CODE_TO_SYMBOL[currencyValue]} ${currencyValue}`}</BoldedText>
              </Flexer>
            )}
            <Flexer gap="4px">
              <FadedText>Language:</FadedText>
              <BoldedText>{SUPPORTED_INVOICE_LANGUAGES[languageValue]}</BoldedText>
            </Flexer>
            <Flexer gap="4px">
              <FadedText>Send all invoices automatically:</FadedText>
              <BoldedText>{autoSendInvoicesValue ? 'On' : 'Off'}</BoldedText>
            </Flexer>
            <Flexer gap="4px">
              <FadedText>Send receipts to customers after payments:</FadedText>
              <BoldedText>{autoSendReceiptValue ? 'On' : 'Off'}</BoldedText>
            </Flexer>
            <Flexer gap="4px">
              <FadedText>Auto-charge the customer:</FadedText>
              <BoldedText>{autoChargeValue ? 'On' : 'Off'}</BoldedText>
            </Flexer>

            <StyledBgImage />
          </SettingsContainer>
          <MainText>Confirm the new settings, and Subscript will re-save related invoices in this schedule.</MainText>
        </ModalBody>

        <ModalFooter flexEnd>
          <ModalButton
            default
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            data-cy="invoice__settings__save-settings__confirm"
            primary
            onClick={() => {
              handleConfirm();
              onClose();
            }}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useSaveSettingsModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), []);

  const Modal = useCallback(
    () => (showModal ? <SaveSettingsModal onClose={() => setShowModal(false)} {...props} /> : null),
    [props, showModal],
  );

  return {
    openSaveSettingsModal: openModal,
    SaveSettingsModal: Modal,
    isSaveSettingsModalVisible: showModal,
  };
};
