import { useState, useEffect } from 'react';
import { getFileByKey, uploadFile } from 'api/files';
import { useToasts } from 'components/Toasts';
import { ALLOWED_EXTENSIONS, ALLOWED_MIME_TYPES, FILE_SIGNATURES } from './consts';

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });

export const useFileUploader = ({ orgId, initialUploadedFile, onFileUpload, privateBucket }) => {
  const { pushToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  useEffect(() => {
    if (initialUploadedFile?.s3FileKey) {
      const fetchFile = async () => {
        setLoading(true);
        try {
          const file = await getFileByKey({ orgId, key: initialUploadedFile.s3FileKey });
          setUploadedFile(file);
        } catch (err) {
          pushToast('Failed to load file!', 'error');
          console.error({ message: err.message, component: 'useFileUploader', stack: err });
        }
        setLoading(false);
      };

      fetchFile();
    }
    // We just want to fetch the file once, so we don't want to re-run this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFile = async ({ file }) => {
    setLoading(true);
    try {
      // Add file type validation
      if (!isValidFileType(file)) {
        throw new Error('Invalid file type. Only allowed file types are supported.');
      }

      const base64File = await fileToBase64(file);

      // Add content type validation
      if (!isValidContentType(base64File, file.type)) {
        throw new Error('Invalid file content. The file does not match its declared type.');
      }

      const uploadData = {
        type: file.type,
        name: sanitizeFileName(file.name),
        data: base64File,
        privateBucket,
      };
      setUploadedFile({
        ...uploadData,
        size: file.size,
      });

      const result = await uploadFile({
        orgId,
        file: uploadData,
      });

      setUploadedFile(result);
      onFileUpload(result);
    } catch (err) {
      pushToast(err.message || 'Failed to upload file!', 'error');
      console.error({ message: err.message, component: 'useFileUploader', stack: err });
    }
    setLoading(false);
  };

  const removeFile = () => {
    setUploadedFile(null);
    onFileUpload(null);
  };

  return {
    loading,
    uploadFile: handleUploadFile,
    uploadedFile,
    removeFile,
  };
};

// Updated helper functions
const isValidFileType = (file) => {
  const fileExtension = file.name.split('.').pop().toLowerCase();
  return ALLOWED_EXTENSIONS.includes(fileExtension);
};

const isValidContentType = (base64File, fileType) => {
  if (!ALLOWED_MIME_TYPES.includes(fileType)) {
    return false;
  }

  const signature = FILE_SIGNATURES[fileType];
  if (signature) {
    return base64File.startsWith(`data:${fileType};base64,${signature}`);
  }

  // For other file types, we'll just check if the declared type matches
  return base64File.startsWith(`data:${fileType};base64,`);
};

const sanitizeFileName = (fileName) => {
  // Remove any characters that aren't alphanumeric, dots, dashes, or underscores
  return fileName.replace(/[^a-zA-Z0-9.-_]/g, '');
};
