import axios from 'axios';
import { API_URL } from 'api/consts';

export const getInvoicingStatistics = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoicingStatistics`, { params });
  return response.data;
};

export const getInvoicingSchedules = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoicing_schedules`, { params });
  return response.data;
};

export const getInvoices = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoices`, { params });
  return response.data;
};

export const getInvoicesCount = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoices-count`, { params });
  return response.data;
};

export const getInvoicesStatusCount = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoices-status-count`, { params });
  return response.data;
};

export const getInvoice = async ({ orgId, invoiceId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoices/${invoiceId}`, { params });
  return response.data;
};

export const getInvoicePdf = async ({ orgId, invoiceId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoices/${invoiceId}/pdf`, { params });
  return response.data;
};

export const bulkDownloadPdf = async ({ orgId, invoiceIds, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-download-pdf`, invoiceIds, {
    params,
  });
  return response.data;
};

export const updateInvoice = async ({ orgId, invoiceId, body, params }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/invoices/${invoiceId}`, body, { params });
  return response.data;
};

export const createInvoice = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices`, body, { params });
  return response.data;
};

export const chargeInvoice = async ({ orgId, invoiceId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/${invoiceId}/charge-invoice`, body, {
    params,
  });
  return response.data;
};

export const bulkUpdateInvoices = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-update`, body);
  return response.data;
};

export const bulkSaveInvoicesToGl = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-save`, body);
  return response.data;
};

export const bulkDetachInvoices = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-detach`, body, {
    params,
  });
  return response.data;
};

export const bulkDeleteInvoices = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-delete`, body, {
    params,
  });
  return response.data;
};

export const bulkResetInvoicesMemo = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-reset-memo`, body);
  return response.data;
};

export const bulkResetInvoicesSecondaryMemo = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-reset-secondary-memo`, body);
  return response.data;
};

export const bulkSendInvoices = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-send`, body, { params });
  return response.data;
};

export const bulkUpdateUnsentInvoices = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/bulk-update-unsent-invoices`, body);
  return response.data;
};

export const deleteInvoice = async ({ orgId, invoiceId, params }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/invoices/${invoiceId}`, { params });
  return response.data;
};

export const regenerateInvoicePDF = async ({ orgId, invoiceId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoices/${invoiceId}/regenerate-invoice-pdf`,
    body,
  );
  return response.data;
};

export const exportInvoicesWithLineItems = async ({ orgId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoices/export-invoices-with-line-items`,
    body,
    {
      responseType: 'blob',
    },
  );
  return response.data;
};

export const dismissInvoicingSchedules = async ({ orgId, transactionIds }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/transactions/invoicing_schedule/dismiss`, {
    transactionIds,
  });
  return response.data;
};

export const addExternalInvoices = async ({ orgId, data, integrationId }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/add-external-invoices`, {
    data,
    integrationId,
  });
  return response.data;
};

export const getInvoicingSchedule = async ({ orgId, invoicingScheduleId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoicing_schedules/${invoicingScheduleId}`, {
    params,
  });
  return response.data;
};

export const deleteInvoicingSchedule = async ({ orgId, invoicingScheduleId }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/invoicing_schedules/${invoicingScheduleId}`);
  return response.data;
};

export const createInvoicingSchedule = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoicing_schedules/`, body, { params });
  return response.data;
};

export const getScheduleWithUnsavedInvoicesData = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/build-new-invoice/`, body, { params });
  return response.data;
};

export const bulkCreateInvoicingSchedule = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoicing_schedules/bulk-create`, body);
  return response.data;
};

export const updateInvoicingSchedule = async ({ orgId, invoicingScheduleId, body }) => {
  const response = await axios.patch(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/${invoicingScheduleId}`,
    body,
  );
  return response.data;
};

export const generateInvoicesJSON = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/generate-invoices-json`, body, {
    params,
  });
  return response.data;
};

export const generateInvoicesCount = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/generate-invoices-count`, body);
  return response.data;
};

export const replaceDynamicVariablesForInvoice = async ({ orgId, invoiceId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoices/${invoiceId}/replace-dynamic-variables`,
    body,
  );
  return response.data;
};

export const syncInvoiceStatuses = async ({ orgId }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/invoices/syncStatuses`);
  return response.data;
};

export const getBillingEmailTemplates = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/billing-email-templates`, { params });
  return response.data;
};

export const getBillingEmailReminders = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/billing-email-reminders`, { params });
  return response.data;
};

export const enableBillingAiReminders = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/billing-email-reminders/enable-ai-reminders`, {
    params,
  });
  return response.data;
};

export const updateBillingEmailTemplate = async ({ orgId, id, body, params }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/billing-email-templates/${id}`, body, {
    params,
  });
  return response.data;
};

export const updateBillingEmailReminder = async ({ orgId, id, body, params }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/billing-email-reminders/${id}`, body, {
    params,
  });
  return response.data;
};

export const createBillingEmailTemplate = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-email-templates`, body);
  return response.data;
};

export const createBillingEmailReminder = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-email-reminders`, body);
  return response.data;
};

export const deleteBillingEmailTemplate = async ({ orgId, id }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/billing-email-templates/${id}`);
  return response.data;
};

export const deleteBillingEmailReminder = async ({ orgId, id }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/billing-email-reminders/${id}`);
  return response.data;
};

export const bulkDeleteBillingEmailReminder = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-email-reminders/bulk-delete`, body);
  return response.data;
};

export const bulkUpdateBillingEmailReminder = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-email-reminders/bulk-update`, body);
  return response.data;
};

export const dismissBillingEmailReminder = async ({ orgId, id }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/billing-email-reminders/${id}/dismiss`);
  return response.data;
};

export const getInvoingScheduleCheckoutData = async ({ id, paymentId, paymentKey, invoiceId }) => {
  const response = await axios.get(`${API_URL}/checkout/${id}`, { params: { paymentId, paymentKey, invoiceId } });
  return response.data;
};

export const exportTransactionsWithoutInvoicingSchedule = async ({ orgId }) => {
  const {
    data,
  } = await axios.get(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/export-transactions-without-invoicing-schedule`,
    { responseType: 'blob' },
  );
  return data;
};

export const generateAiReminderForCustomer = async ({ orgId, customerId }) => {
  const response = await axios.get(
    `${API_URL}/organizations/${orgId}/smart_dunning/generate-ai-reminder-for-customer/${customerId}`,
  );
  return response.data;
};

export const importTransactionsWithInvoiceData = async ({ orgId, invoicing_schedules, invoices, integrationId }) => {
  const formData = new FormData();
  formData.append('csvFiles', invoicing_schedules);
  formData.append('csvFiles', invoices);
  formData.append('integrationId', integrationId);

  const { data } = await axios.post(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/import-transactions-with-invoice-data`,
    formData,
    {
      responseType: 'blob',
    },
  );
  return data;
};

export const getBillingAgingReport = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoices/ar-aging-report`, { params });
  return response.data;
};

export const searchResources = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/integrations/search`, { params });
  return response.data;
};

export const getExternalResourceById = async ({ orgId, id, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/integrations/resource/${id}`, { params });
  return response.data;
};

export const regenerateInvoicingSchedule = async ({ orgId, invoicingScheduleId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/${invoicingScheduleId}/regenerate`,
    body,
  );
  return response.data;
};

export const resyncInvoicingSchedule = async ({ orgId, invoicingScheduleId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/${invoicingScheduleId}/resync`,
    body,
  );
  return response.data;
};

export const applyScheduleInvoiceChanges = async ({ orgId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/apply-invoice-changes`,
    body,
  );
  return response.data;
};

export const regenerateDraftInvoices = async ({ orgId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/regenerate-draft-invoices`,
    body,
  );
  return response.data;
};

export const regenerateAutoChargePaymentKey = async ({ orgId, invoicingScheduleId, body }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/invoicing_schedules/${invoicingScheduleId}/regenerate-auto-charge-payment-key`,
    body,
  );
  return response.data;
};

export const getCreditNotes = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/credit-notes`, { params });
  return response.data;
};

export const getCreditNoteTitles = async ({ orgId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/credit-notes/titles`);
  return response.data;
};

export const getCreditNoteById = async ({ orgId, creditNoteId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}`, { params });
  return response.data;
};

export const getCreditNotePdf = async ({ orgId, creditNoteId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}/pdf`, { params });
  return response.data;
};

export const regenerateCreditNotePdf = async ({ orgId, creditNoteId, body, params }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}/regenerate-pdf`,
    body,
    { params },
  );
  return response.data;
};

export const createCreditNote = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/credit-notes`, body);
  return response.data;
};

export const updateCreditNote = async ({ orgId, creditNoteId, body }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}`, body);
  return response.data;
};

export const sendCreditNote = async ({ orgId, creditNoteId, params }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}/send-email`,
    {},
    {
      params,
    },
  );
  return response.data;
};

export const deleteCreditNote = async ({ orgId, creditNoteId, detachMode, integrationId }) => {
  const integrationIdQuery = integrationId ? `&integrationId=${integrationId}` : '';
  const response = await axios.delete(
    `${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}?detachMode=${detachMode}${integrationIdQuery}`,
  );
  return response.data;
};

export const voidCreditNote = async ({ orgId, creditNoteId, integrationId }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}/void`, undefined, {
    params: { integrationId },
  });
  return response.data;
};

export const creditNoteAllocate = async ({ orgId, creditNoteId, body }) => {
  const response = await axios.put(`${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}/allocations`, body);
  return response.data;
};

export const creditNoteExternalUpdate = async ({ orgId, creditNoteId, body, params }) => {
  const response = await axios.put(`${API_URL}/organizations/${orgId}/credit-notes/${creditNoteId}/external`, body, {
    params,
  });
  return response.data;
};

export const getBillingInconsistencies = async ({ orgId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/invoicing_schedules/billing-inconsistencies`);
  return response.data;
};

export const getRevRecExceptions = async ({ orgId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/transactions/rev-rec-exceptions`);
  return response.data;
};

export const dismissTransactionFromBillingInconsistencies = async ({ orgId, transactionId }) => {
  const body = { transactionId };
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/transactions/billing-inconsistencies/dismiss`,
    body,
  );
  return response.data;
};

export const dismissTransactionFromRevRecExceptions = async ({ orgId, transactionId }) => {
  const body = { transactionId };
  const response = await axios.post(`${API_URL}/organizations/${orgId}/transactions/rev-rec-exceptions/dismiss`, body);
  return response.data;
};
