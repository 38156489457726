import React, { useState, useContext } from 'react';
import { Row, Spacer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from 'components/Modal';
import { TextBox } from 'components/Core/Text';
import { AppContext } from 'AppContext';
import { TransactionPreviewTable } from './TransactionsPreviewTable';
import { useContractsAPI } from 'api/contracts';

export const ContractAIPreviewModal = ({ data, onClose, onSubmit }) => {
  const [dataToBulkUpload, setDataToBulkUpload] = useState([]);

  const { orgId } = useContext(AppContext);

  const {
    operations: { generateFromContract },
  } = useContractsAPI({
    orgId,
    autoFetch: false,
  });

  const convertInterpretedValuesToTableData = ({ data }) => {
    return (
      data?.lineItems?.map((lineItem) => {
        const result = {
          organization_id: data.organization_id ?? orgId,
          contract_id: data.contract_id,

          interpreter_product_name: lineItem.product_name ?? lineItem.name,
          interpreter_customer_name: lineItem.customer_name,
          customer_confidence: lineItem.guessed_customer_confidence,
          customer_id: lineItem.guessed_customer_id,
          customer_name: lineItem.guessed_customer_name,
          product_confidence: lineItem.guessed_product_confidence,
          product_id: lineItem.guessed_product_id,
          product_name: lineItem.guessed_product_name,

          name: lineItem.name,
          amount: lineItem.amount,
          seats: lineItem.seats,
          recognition: lineItem.recognition ?? (lineItem.recurring ? 'linear' : 'immediate'),

          metadata: { ...data?.metadata, ...lineItem?.metadata },
          confirmed: true,

          date: lineItem.date,
          start_date: lineItem.start_date,
          end_date: lineItem.end_date,
        };

        return result;
      }) ?? []
    );
  };

  const tableData = convertInterpretedValuesToTableData({ data });

  return (
    <ModalContainer data-cy="contract-files-modal">
      <Modal width="80vw" overflow="visible" padding="0px">
        <ModalHeader padding>
          <Row>
            <TextBox bold>Check and select the transactions that </TextBox>
            <Spacer width="5px" />
            <TextBox bold italic opacity="0.5">
              Subscript AI will create
            </TextBox>
          </Row>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <ModalBody centered>
          <TransactionPreviewTable data={tableData} setDataHook={setDataToBulkUpload} />
        </ModalBody>

        <ModalFooter flexEnd>
          <ModalButton default onClick={onClose}>
            Cancel
          </ModalButton>
          <Spacer width="14px" />
          <ModalButton
            primary
            disabled={!dataToBulkUpload?.length}
            onClick={async () => {
              const result = await generateFromContract.mutateAsync({ data: dataToBulkUpload, id: data.contract_id });
              onSubmit?.(result);
            }}
          >
            Confirm Transactions
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
