import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useInvoicesCountAPI } from 'api/billing';
import { useIntegrationsAPI } from 'api/integrations';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { useConfirmModal } from 'shared/ConfirmModal';
import { Centerer, Flexer } from 'components/Core';
import { BinIcon } from 'components/Icons';
import { IconButton } from 'components/Buttons';
import { CircleLoader } from 'components/Loaders';
import { SERVICE_WITH_DISCONNECT_ROUTE } from 'views/Billing/consts';

const ModalContentWrapper = styled(Flexer)`
  width: 100%;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const WarningBox = styled(Flexer)`
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: var(--tertiaryRed5);
`;

const WarningBoxTitle = styled(Flexer)`
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
`;

const WarningBoxText = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: var(--primaryBlack5);
`;

export const DisconnectIntegrationButton = ({ selectedIntegration }) => {
  const { orgId, integrations, setIntegrations } = useContext(AppContext);
  const {
    operations: { disconnectIntegration },
  } = useIntegrationsAPI({ orgId, autoFetch: false });
  const integrationService = getServiceCategory(selectedIntegration?.service);
  const integrationName = getIntegrationDisplayName(selectedIntegration);

  const [autoFetch, setAutoFetch] = useState(false);
  const { data: invoicesCount, isLoading } = useInvoicesCountAPI({
    orgId,
    params: { integrationIds: [selectedIntegration.id] },
    autoFetch,
  });

  const handleClickRemoveButton = () => {
    setAutoFetch(true);
    openConfirmModal();
  };

  const handleDisconnect = async () => {
    await disconnectIntegration.mutateAsync(
      { integrationId: selectedIntegration.id, integrationService },
      {
        onSuccess: () => {
          setIntegrations(integrations.filter((integration) => integration.id !== selectedIntegration.id));
          window.location.replace('/');
        },
      },
    );
  };

  const { ConfirmModal, openConfirmModal } = useConfirmModal({
    title: 'Remove integration',
    content: (
      <ModalContentWrapper>
        We only recommend this if you truly will not be using this integration again. You may instead want to reconnect
        it.
        <br />
        This cannot be un-done.
        <WarningBox>
          <WarningBoxTitle>
            <BinIcon />
            {integrationName}
          </WarningBoxTitle>
          <Line />
          {isLoading ? (
            <Centerer width="100%">
              <CircleLoader />
            </Centerer>
          ) : (
            <WarningBoxText>
              This integration has {invoicesCount} invoices that will stay in Subscript but{' '}
              <b>no longer be connected</b> to {integrationName}.
            </WarningBoxText>
          )}
        </WarningBox>
      </ModalContentWrapper>
    ),
    width: '440px',
    contentPadding: '36px',
    closeButtonText: 'Cancel',
    onClose: () => {},
    deleteButtonText: 'Remove',
    onDelete: handleDisconnect,
  });

  if (!SERVICE_WITH_DISCONNECT_ROUTE.includes(integrationService)) return <></>;
  return (
    <>
      <IconButton icon={<BinIcon />} onClick={handleClickRemoveButton}>
        Remove
      </IconButton>
      <ConfirmModal />
    </>
  );
};
