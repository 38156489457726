import React from 'react';
import { ContractsListHeader } from './ContractsListHeader';
import { ContractsListTable } from './ContractsListTable';
import { useMVP } from 'utils/hooks';

export const ContractsListContainer = () => {
  const isMVP = useMVP();

  if (!isMVP) return null;

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <ContractsListHeader />
      <ContractsListTable />
    </div>
  );
};
