import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'animate.css';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-doughnutlabel';
import { AppContextProvider } from 'AppContext';
import { CircleLoader } from 'components/Loaders';
import { ToastsProvider } from 'components/Toasts';
import { ProtectedRoute } from 'shared/Auth';
import NoOrganization from 'views/NoOrganization/NoOrganization';
import Wizard from 'views/Wizard/Wizard';
import { Wizard as Wizard2 } from 'views/Wizard'; // this is temporary so that we can have both pages until we want to remove one
import { InvoiceCheckoutPage } from 'views/Billing/InvoiceCheckout/InvoiceCheckoutPage';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from 'views/Billing/consts';
import { ObserverProvider } from 'utils/observable/ObservableProvider';
import { useDataFilter } from 'utils/hooks';
import { SUPPORTED_LOCALES_BY_COUNTRY } from 'consts/global';
import routes from 'routes';
import {
  addProduct,
  setAxiosTokenInterceptor,
  fetchOrganisationIds,
  fetchIntegrations,
  fetchOrgConfigs,
} from './utils';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(localeData);

Chart.plugins.register(ChartDataLabels);
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  display: false,
});

const DAYJS_LOCALES = {
  cs: { promise: import('dayjs/locale/cs'), locale: 'cs' },
  en: { promise: import('dayjs/locale/en'), locale: 'en' },
  'en-GB': { promise: import('dayjs/locale/en-gb'), locale: 'en-gb' },
  'en-CA': { promise: import('dayjs/locale/en-ca'), locale: 'en-ca' },
  'en-ZA': { promise: import('dayjs/locale/en-ca'), locale: 'en-ca' }, // South africa
  'en-AU': { promise: import('dayjs/locale/en-au'), locale: 'en-au' },
  es: { promise: import('dayjs/locale/es'), locale: 'es' },
  'es-ES': { promise: import('dayjs/locale/es'), locale: 'es' },
  'es-MX': { promise: import('dayjs/locale/es'), locale: 'es' },
  'de-DE': { promise: import('dayjs/locale/de'), locale: 'de' }, // Germany
  fr: { promise: import('dayjs/locale/fr'), locale: 'fr' },
  'fr-FR': { promise: import('dayjs/locale/fr'), locale: 'fr' },
  id: { promise: import('dayjs/locale/id'), locale: 'id' },
  it: { promise: import('dayjs/locale/it'), locale: 'it' },
  nl: { promise: import('dayjs/locale/nl'), locale: 'nl' },
  pt: { promise: import('dayjs/locale/pt'), locale: 'pt' },
  'pt-br': { promise: import('dayjs/locale/pt-br'), locale: 'pt-br' },
  ro: { promise: import('dayjs/locale/ro'), locale: 'ro' },
  ru: { promise: import('dayjs/locale/ru'), locale: 'ru' },
  vi: { promise: import('dayjs/locale/vi'), locale: 'vi' },
  'en-sg': { promise: import('dayjs/locale/en-sg'), locale: 'en-sg' },
};

const DAYJS_LOCALES_STRING = Object.keys(DAYJS_LOCALES).reduce(
  (obj, language) => ({
    ...obj,
    [language]: language,
  }),
  {},
);

const DAYJS_LOCALES_BY_COUNTRY = {
  [SUPPORTED_LOCALES_BY_COUNTRY.CZECH]: DAYJS_LOCALES_STRING.cs,
  [SUPPORTED_LOCALES_BY_COUNTRY.USA]: DAYJS_LOCALES_STRING.en,
  [SUPPORTED_LOCALES_BY_COUNTRY.UK]: DAYJS_LOCALES_STRING['en-GB'],
  [SUPPORTED_LOCALES_BY_COUNTRY.CANADA]: DAYJS_LOCALES_STRING['en-CA'],
  [SUPPORTED_LOCALES_BY_COUNTRY.SOUTH_AFRICA]: DAYJS_LOCALES_STRING['en-ZA'],
  [SUPPORTED_LOCALES_BY_COUNTRY.AUSTRALIA]: DAYJS_LOCALES_STRING['en-AU'],
  [SUPPORTED_LOCALES_BY_COUNTRY.SOUTH_AMERICA]: DAYJS_LOCALES_STRING.es,
  [SUPPORTED_LOCALES_BY_COUNTRY.MEXICO]: DAYJS_LOCALES_STRING['es-MX'],
  [SUPPORTED_LOCALES_BY_COUNTRY.GERMANY]: DAYJS_LOCALES_STRING['de-DE'],
  [SUPPORTED_LOCALES_BY_COUNTRY.FRANCE]: DAYJS_LOCALES_STRING.fr,
  [SUPPORTED_LOCALES_BY_COUNTRY.INDONESIA]: DAYJS_LOCALES_STRING.id,
  [SUPPORTED_LOCALES_BY_COUNTRY.ITALY]: DAYJS_LOCALES_STRING.it,
  [SUPPORTED_LOCALES_BY_COUNTRY.BELGIUM]: DAYJS_LOCALES_STRING.nl,
  [SUPPORTED_LOCALES_BY_COUNTRY.NETHERLANDS]: DAYJS_LOCALES_STRING.nl,
  [SUPPORTED_LOCALES_BY_COUNTRY.PORTUGAL]: DAYJS_LOCALES_STRING.pt,
  [SUPPORTED_LOCALES_BY_COUNTRY.BRAZIL]: DAYJS_LOCALES_STRING['pt-br'],
  [SUPPORTED_LOCALES_BY_COUNTRY.ROMANIA]: DAYJS_LOCALES_STRING.ro,
  [SUPPORTED_LOCALES_BY_COUNTRY.RUSSIA]: DAYJS_LOCALES_STRING.ru,
  [SUPPORTED_LOCALES_BY_COUNTRY.VIETNAM]: DAYJS_LOCALES_STRING.vi,
  [SUPPORTED_LOCALES_BY_COUNTRY.SINGAPORE]: DAYJS_LOCALES_STRING['en-sg'],
  [SUPPORTED_LOCALES_BY_COUNTRY.HONG_KONG]: DAYJS_LOCALES_STRING['en-sg'],
};

const addDayJSLocale = ({ language, onLocaleSet }) => {
  const localePromise = DAYJS_LOCALES[language].promise;
  localePromise.then(() => {
    dayjs.locale(DAYJS_LOCALES[language].locale);
    onLocaleSet();
    // we collect the locale data that we need and switch back to 'en'
    // this is to avoid invalid dates being generated due to some locales having languages other than English
    dayjs.locale('en');
  });
};

const isSubscriptEmail = (email = '') => email?.includes('@subscript.com') || email?.includes('@chiffer.co');

const App = () => {
  const { getAccessTokenSilently, isAuthenticated, user, logout } = useAuth0();
  const [notAuthenticatedUser, setNotAuthenticatedUser] = useState(false);
  const [organizations, setOrganizations] = useState(null);
  const [integrations, setIntegrations] = useState([]);
  const [loadingOrgs, setLoadingOrgs] = useState(true);
  const [dataFilter, setDataFilter] = useDataFilter();
  const [orgConfigs, setOrgConfigs] = useState();
  const [dateFormat, setDateFormat] = useState(null);

  useEffect(() => {
    Sentry.setUser({ email: user?.email });
  }, [user?.email]);

  // Add a 401 response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        logout();
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    const language =
      DAYJS_LOCALES_BY_COUNTRY[orgConfigs?.locale_region ?? ''] ??
      (DAYJS_LOCALES[navigator.language] ? navigator.language : 'en');
    addDayJSLocale({
      language,
      onLocaleSet: () => {
        setDateFormat(dayjs.localeData().longDateFormat('L'));
      },
    });
  }, [orgConfigs?.locale_region]);

  useEffect(() => {
    if (!organizations && isAuthenticated) {
      let token;
      (async () => {
        try {
          token = await getAccessTokenSilently();
          setAxiosTokenInterceptor(token);
          fetchOrganisationIds({ setLoadingOrgs, setOrganizations, setNotAuthenticatedUser });
        } catch (e) {
          console.error(e);
        }
      })();
    }

    if (organizations) {
      fetchIntegrations({ setIntegrations, organizations });
      fetchOrgConfigs({ organizations, setOrgConfigs });
    }
    // eslint-disable-next-line
  }, [organizations, isAuthenticated]);

  // If the organizations prop is an array that means the api call succeeded but user has no orgs.
  // and we will show the no organization component
  if (!loadingOrgs && (notAuthenticatedUser || (Array.isArray(organizations) && !organizations.length))) {
    return <NoOrganization />;
  }

  // If organizations = null that means the api call failed so it will keep loading
  if (loadingOrgs && isAuthenticated) {
    return (
      <div data-cy="main-spinner">
        <CircleLoader isAbsolute />
      </div>
    );
  }

  if (
    process.env.REACT_APP_ENVIRONMENT === 'production' &&
    !loadingOrgs &&
    isAuthenticated &&
    !isSubscriptEmail(user?.email) &&
    !user?.email_verified
  ) {
    // if the user is not a subscript email and the email is not verified, take them to a temporary page
    return <NoOrganization verifyEmail={true} />;
  }

  return (
    // React Router preserves browser history
    <Router>
      <section id="main">
        <ToastsProvider>
          <Switch>
            {/*
            Redirect from /billing/reminders to invoices page showing reminders
            See template: https://mc.sendgrid.com/dynamic-templates/d-599ca1113ee8487495ed6fcb812cd2c0/version/d8852d54-c3f1-4770-8668-226328ed87ba
            [JB 2023-10-18] this seems like the simplest/quickest way to do this, but it's not ideal because it will cause a redirect
            however because we are using state in the invoices page to filter by status, we can't just change the url for now
            we would need to refactor the invoices page to use query params instead of state
           */}
            <Redirect
              from="/billing/reminders"
              to={{
                pathname: BILLING_PAGES_ROUTES.INVOICES,
                state: { status: INVOICE_STATUSES.SENT },
              }}
            />

            {routes.map((route, idx) => {
              return (
                <ProtectedRoute
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  loadedOrgConfigs={!!orgConfigs}
                  component={(props) => (
                    <AppContextProvider
                      dataFilter={dataFilter}
                      setDataFilter={setDataFilter}
                      organizations={organizations}
                      setOrganizations={setOrganizations}
                      integrations={integrations}
                      setIntegrations={setIntegrations}
                      addProduct={({ product }) => addProduct({ product, organizations, setOrganizations })}
                      orgConfigs={orgConfigs}
                      setOrgConfigs={setOrgConfigs}
                      dateFormat={dateFormat}
                      formatDateWithLocale={(date) => dayjs.utc(date).format(dateFormat)}
                    >
                      <ObserverProvider>
                        {route.layout ? (
                          <route.layout layoutProps={route.layoutProps}>
                            <route.view {...props} />
                          </route.layout>
                        ) : (
                          <route.view {...props} />
                        )}
                      </ObserverProvider>
                    </AppContextProvider>
                  )}
                />
              );
            })}

            <Route path="/wizard" exact={true} component={Wizard} />
            <Route path="/wizard2" exact={true} component={Wizard2} />
            <Route path="/checkout/:id" exact={true} component={InvoiceCheckoutPage} />
          </Switch>
        </ToastsProvider>
      </section>
    </Router>
  );
};

export default App;
