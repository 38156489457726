export const INTEGRATION_TYPES = {
  CRM: 'CRM',
  GL: 'GL',
  Analytics: 'Analytics',
  Billing: 'Billing',
  CSV: 'CSV',
  MAILER: 'Mailer',
};

export const SERVICE_DELIMITER = '.';

export const INTEGRATION_SERVICES = {
  HUBSPOT: 'hubspot',
  SALESFORCE: 'salesforce',
  MAXIO: 'maxio',
  QUICKBOOKS: 'quickbooks',
  NETSUITE: 'netsuite',
  STRIPE: 'stripe',
  SLACK: 'slack',
  EMAIL: 'email',
  XERO: 'xero',
  GMAIL: 'gmail',
};

export const GMAIL_FEATURES = {
  SEND_EMAIL: 'send_email',
  AI_REMINDERS: 'ai_reminders',
};

export const MAILER_SERVICES_READ_SCOPE = {
  [INTEGRATION_SERVICES.GMAIL]: 'https://www.googleapis.com/auth/gmail.readonly',
};

export const GL_INTEGRATION_SERVICES = [
  INTEGRATION_SERVICES.QUICKBOOKS,
  INTEGRATION_SERVICES.NETSUITE,
  INTEGRATION_SERVICES.XERO,
];

export const CRM_INTEGRATION_SERVICES = [INTEGRATION_SERVICES.HUBSPOT, INTEGRATION_SERVICES.SALESFORCE];

export const BILLING_INTEGRATION_SERVICES = [INTEGRATION_SERVICES.STRIPE];

export const INTEGRATION_METADATA_FIELDS = {
  SKIP_DAILY_SYNC: 'skip_daily_sync',
  REPORT_FOR_SHARE: 'report_for_share',
  NON_MEMBERS_EMAILS: 'non_members_emails_for_weekly_report',
  EMAIL_FREQUENCY: 'email_recurring_reports_frequency',
  SLACK_FREQUENCY: 'slack_recurring_reports_frequency',
};

export const INTEGRATION_SERVICES_WITHOUT_RECONNECT = [INTEGRATION_SERVICES.NETSUITE];

export const INTEGRATION_SERVICES_WITH_OAUTH = [INTEGRATION_SERVICES.QUICKBOOKS];

export const INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE = [
  INTEGRATION_SERVICES.XERO,
  INTEGRATION_SERVICES.QUICKBOOKS,
  'manual',
  '', // no integration resolves to this
];

export const INTEGRATION_SERVICES_WITH_VOID_CREDIT_NOTE =
  process.env.REACT_APP_RUN_ENV === 'e2e-test'
    ? [INTEGRATION_SERVICES.XERO, INTEGRATION_SERVICES.QUICKBOOKS]
    : [INTEGRATION_SERVICES.XERO];

export const INTEGRATIONS_OPERATIONS = {
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  DISCONNECT: 'DISCONNECT',
};

export const INTEGRATION_FIELD_MAPPINGS = {
  name: {
    [INTEGRATION_SERVICES.HUBSPOT]: 'name',
    [INTEGRATION_SERVICES.SALESFORCE]: 'name',
    [INTEGRATION_SERVICES.NETSUITE]: 'companyname',
    [INTEGRATION_SERVICES.QUICKBOOKS]: 'DisplayName',
    [INTEGRATION_SERVICES.XERO]: 'Name',
    [INTEGRATION_SERVICES.STRIPE]: 'name',
  },
  emailAddresses: {
    [INTEGRATION_SERVICES.XERO]: 'emailAddresses',
  },
  invoicingDetails: {
    [INTEGRATION_SERVICES.XERO]: 'invoicing_details',
    [INTEGRATION_SERVICES.QUICKBOOKS]: 'invoicing_details',
    [INTEGRATION_SERVICES.NETSUITE]: 'invoicing_details',
    [INTEGRATION_SERVICES.SALESFORCE]: 'invoicing_details',
  },
};

// taken from https://www.netsuite.com/help/helpcenter/en_US/srbrowser/Browser2017_2/schema/enum/country.html?mode=package
export const NETSUITE_SUPPORTED_COUNTRIES = [
  '_afghanistan',
  '_alandIslands',
  '_albania',
  '_algeria',
  '_americanSamoa',
  '_andorra',
  '_angola',
  '_anguilla',
  '_antarctica',
  '_antiguaAndBarbuda',
  '_argentina',
  '_armenia',
  '_aruba',
  '_australia',
  '_austria',
  '_azerbaijan',
  '_bahamas',
  '_bahrain',
  '_bangladesh',
  '_barbados',
  '_belarus',
  '_belgium',
  '_belize',
  '_benin',
  '_bermuda',
  '_bhutan',
  '_bolivia',
  '_bonaireSaintEustatiusAndSaba',
  '_bosniaAndHerzegovina',
  '_botswana',
  '_bouvetIsland',
  '_brazil',
  '_britishIndianOceanTerritory',
  '_bruneiDarussalam',
  '_bulgaria',
  '_burkinaFaso',
  '_burundi',
  '_cambodia',
  '_cameroon',
  '_canada',
  '_canaryIslands',
  '_capeVerde',
  '_caymanIslands',
  '_centralAfricanRepublic',
  '_ceutaAndMelilla',
  '_chad',
  '_chile',
  '_china',
  '_christmasIsland',
  '_cocosKeelingIslands',
  '_colombia',
  '_comoros',
  '_congoDemocraticPeoplesRepublic',
  '_congoRepublicOf',
  '_cookIslands',
  '_costaRica',
  '_coteDIvoire',
  '_croatiaHrvatska',
  '_cuba',
  '_curacao',
  '_cyprus',
  '_czechRepublic',
  '_denmark',
  '_djibouti',
  '_dominica',
  '_dominicanRepublic',
  '_eastTimor',
  '_ecuador',
  '_egypt',
  '_elSalvador',
  '_equatorialGuinea',
  '_eritrea',
  '_estonia',
  '_ethiopia',
  '_falklandIslands',
  '_faroeIslands',
  '_fiji',
  '_finland',
  '_france',
  '_frenchGuiana',
  '_frenchPolynesia',
  '_frenchSouthernTerritories',
  '_gabon',
  '_gambia',
  '_georgia',
  '_germany',
  '_ghana',
  '_gibraltar',
  '_greece',
  '_greenland',
  '_grenada',
  '_guadeloupe',
  '_guam',
  '_guatemala',
  '_guernsey',
  '_guinea',
  '_guineaBissau',
  '_guyana',
  '_haiti',
  '_heardAndMcDonaldIslands',
  '_holySeeCityVaticanState',
  '_honduras',
  '_hongKong',
  '_hungary',
  '_iceland',
  '_india',
  '_indonesia',
  '_iranIslamicRepublicOf',
  '_iraq',
  '_ireland',
  '_isleOfMan',
  '_israel',
  '_italy',
  '_jamaica',
  '_japan',
  '_jersey',
  '_jordan',
  '_kazakhstan',
  '_kenya',
  '_kiribati',
  '_koreaDemocraticPeoplesRepublic',
  '_koreaRepublicOf',
  '_kosovo',
  '_kuwait',
  '_kyrgyzstan',
  '_laoPeoplesDemocraticRepublic',
  '_latvia',
  '_lebanon',
  '_lesotho',
  '_liberia',
  '_libya',
  '_liechtenstein',
  '_lithuania',
  '_luxembourg',
  '_macau',
  '_macedonia',
  '_madagascar',
  '_malawi',
  '_malaysia',
  '_maldives',
  '_mali',
  '_malta',
  '_marshallIslands',
  '_martinique',
  '_mauritania',
  '_mauritius',
  '_mayotte',
  '_mexico',
  '_micronesiaFederalStateOf',
  '_moldovaRepublicOf',
  '_monaco',
  '_mongolia',
  '_montenegro',
  '_montserrat',
  '_morocco',
  '_mozambique',
  '_myanmar',
  '_namibia',
  '_nauru',
  '_nepal',
  '_netherlands',
  '_newCaledonia',
  '_newZealand',
  '_nicaragua',
  '_niger',
  '_nigeria',
  '_niue',
  '_norfolkIsland',
  '_northernMarianaIslands',
  '_norway',
  '_oman',
  '_pakistan',
  '_palau',
  '_panama',
  '_papuaNewGuinea',
  '_paraguay',
  '_peru',
  '_philippines',
  '_pitcairnIsland',
  '_poland',
  '_portugal',
  '_puertoRico',
  '_qatar',
  '_reunionIsland',
  '_romania',
  '_russianFederation',
  '_rwanda',
  '_saintBarthelemy',
  '_saintHelena',
  '_saintKittsAndNevis',
  '_saintLucia',
  '_saintMartin',
  '_saintVincentAndTheGrenadines',
  '_samoa',
  '_sanMarino',
  '_saoTomeAndPrincipe',
  '_saudiArabia',
  '_senegal',
  '_serbia',
  '_seychelles',
  '_sierraLeone',
  '_singapore',
  '_sintMaarten',
  '_slovakRepublic',
  '_slovenia',
  '_solomonIslands',
  '_somalia',
  '_southAfrica',
  '_southGeorgia',
  '_southSudan',
  '_spain',
  '_sriLanka',
  '_stateOfPalestine',
  '_stPierreAndMiquelon',
  '_sudan',
  '_suriname',
  '_svalbardAndJanMayenIslands',
  '_swaziland',
  '_sweden',
  '_switzerland',
  '_syrianArabRepublic',
  '_taiwan',
  '_tajikistan',
  '_tanzania',
  '_thailand',
  '_togo',
  '_tokelau',
  '_tonga',
  '_trinidadAndTobago',
  '_tunisia',
  '_turkey',
  '_turkmenistan',
  '_turksAndCaicosIslands',
  '_tuvalu',
  '_uganda',
  '_ukraine',
  '_unitedArabEmirates',
  '_unitedKingdom',
  '_unitedStates',
  '_uruguay',
  '_uSMinorOutlyingIslands',
  '_uzbekistan',
  '_vanuatu',
  '_venezuela',
  '_vietnam',
  '_virginIslandsBritish',
  '_virginIslandsUSA',
  '_wallisAndFutunaIslands',
  '_westernSahara',
  '_yemen',
  '_zambia',
  '_zimbabwe',
];
