import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'images/close_modal.svg';
import { ReactComponent as CircleCheck } from 'images/circle-check-white.svg';
import { BinIcon } from 'components/Icons';
import { COLORS } from 'consts/colors';
import { Flexer } from 'components/Core';
import { Button } from '../Buttons';

const LayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 21, 46, 0.8);
  height: 100vh;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex ?? 50};
`;

const Modal = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'column'};

  background: ${({ background }) => background ?? '#ffffff'};
  border-radius: 20px;
  box-shadow: 0px 32px 120px 0 rgba(0, 21, 46, 0.4);
  height: ${({ height }) => height || '80vh'};
  max-height: ${({ maxHeight, height }) => maxHeight || height || '80vh'};
  min-height: ${({ minHeight }) => minHeight ?? 'auto'};
  max-width: ${({ maxWidth, width }) => maxWidth || width || '60vw'};
  min-width: ${({ minWidth }) => minWidth ?? 'auto'};
  padding: ${({ compact, padding }) => padding ?? (compact ? '20px' : '30px')} 0 0;
  width: ${({ width }) => width || '60vw'};
  position: ${({ position }) => position || 'relative'};
  overflow: ${({ overflow }) => overflow || 'hidden'};

  animation-duration: 0.3s;
  animation-name: modalIn;
  animation-timing-function: ease;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  @keyframes modalIn {
    from {
      opacity: 0.2;
      bottom: -30px;
    }

    to {
      opacity: 1;
      bottom: 0;
    }
  }
`;

const FullscreenModal = ({ children, ...rest }) => (
  <Modal
    position="fixed"
    maxWidth="1920px"
    height="95vh"
    width="100%"
    overflow="visible"
    style={{ paddingTop: 0, bottom: 0 }}
    {...rest}
  >
    {children}
  </Modal>
);

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  // Add extra padding
  padding: ${({ padding }) => padding && '20px 40px'};
  // Add bottom line
  border-bottom: ${({ bottomSeparator }) => bottomSeparator && '1px solid var(--accentGraySecond)'};
`;

const ModalTitleText = styled.div`
  font-size: ${({ compact }) => (compact ? '20px' : '24px')};

  b {
    font-weight: 900;
  }

  .subTitle {
    color: rgba(0, 21, 46, 0.3);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 4px;
  }
`;

const ModalSubtitleText = styled.p`
  color: rgba(0, 21, 46, 0.3);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
`;

const ModalTitle = styled(ModalTitleText)`
  line-height: ${({ compact }) => (compact ? '26px' : '38px')};
  padding: ${({ padding, compact }) => padding ?? `0 0 ${compact ? '10px' : 0} ${compact ? 0 : '36px'}`};
  margin: ${({ compact, margin }) => margin ?? (compact && 'auto')};
  font-weight: ${({ bold }) => bold && 900};
`;

const ModalBody = styled.div`
  display: flex;
  font-size: ${({ compact }) => (compact ? '12px' : 'inherit')};
  line-height: ${({ compact }) => (compact ? '18px' : 'inherit')};
  white-space: normal;
  flex-direction: column;
  flex: 1;
  height: ${({ height }) => height ?? 'initial'};
  max-height: ${({ height }) => height ?? '95%'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'initial'};
  overflow: ${({ overflow }) => overflow ?? 'auto'};
  padding-left: ${({ compact, paddingLeft }) => paddingLeft ?? (compact ? '22px' : '36px')};
  padding-right: ${({ compact, paddingRight }) => paddingRight ?? (compact ? '22px' : '36px')};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  text-align: ${({ centered }) => (centered ? 'center' : undefined)};
  gap: ${({ gap }) => gap ?? '12px'};
`;

// TODO: Migrate all ModalBody to ModalBodyMargin to keep consistency
const ModalBodyMargin = styled(ModalBody)`
  margin: 15px 0 30px 0;
  padding: ${({ padding }) => padding};
`;

const ModalRedSection = styled(Flexer)`
  padding: 16px;
  border-radius: 8px;
  background: var(--tertiaryRed5);
`;

const ModalRedSectionTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
`;

const ModalRedSectionText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  opacity: 0.7;
`;

const ModalFooter = styled.div`
  background-color: ${({ transparent }) => (transparent ? undefined : 'var(--accentGrayer)')};
  border-radius: 0px 0px 20px 20px;
  display: ${({ centered, flexEnd }) => (centered || flexEnd ? 'flex' : undefined)};
  height: ${({ noFixedHeight, height }) => (noFixedHeight ? undefined : height ?? '80px')};
  justify-content: ${({ centered, flexEnd }) => (centered ? 'center' : flexEnd ? 'flex-end' : undefined)};
  padding: ${({ noPadding, padding }) => (noPadding ? undefined : padding ?? '20px 50px')};
  // Add bottom line
  border-top: ${({ topSeparator }) => topSeparator && '1px solid var(--accentGraySecond)'};
  gap: ${({ gap }) => gap ?? '8px'};
  width: ${({ width }) => width ?? 'initial'};
`;

const ModalFooterText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  opacity: 0.5;
`;

const ModalButtonDeprecated = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  height: 40px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  box-sizing: border-box;
  border-radius: 100px;
  font-weight: ${(props) => props.fontWeight ?? '400'};
  margin: 0 15px 0 0;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled && '0.3'};

  &.primary {
    background: var(--primaryGreen);
    color: #ffffff;
  }
`;

const CheckIcon = styled(CircleCheck)`
  margin-left: 10px;

  height: 20px;
  width: 20px;
`;

const StyledBinIcon = styled(BinIcon)`
  margin-left: 10px;

  height: 20px;
  width: 20px;

  path {
    fill: #fff;
  }
`;

const ModalButton = (props) => {
  if (props.primary) {
    return (
      <Button color={COLORS.GREEN} border filled active {...props}>
        {props.children}
        {!props.hideIcon && <CheckIcon />}
      </Button>
    );
  } else if (props.delete) {
    return (
      <Button color={COLORS.RED} border filled active {...props}>
        {props.children}
        {!props.hideIcon && <StyledBinIcon />}
      </Button>
    );
  } else if (props.default) {
    return (
      <Button border filled color={COLORS.GREY} {...props}>
        {props.children}
      </Button>
    );
  }

  return <ModalButtonDeprecated {...props} />;
};

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  width: ${({ size }) => size ?? '32px'};
  height: ${({ size }) => size ?? '32px'};
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalCloseIcon = ({ onClose, ...rest }) => <StyledCloseIcon onClick={onClose} {...rest} />;

const ModalContainer = ({ children, onClick, ...rest }) => (
  <LayoutContainer onClick={onClick} {...rest}>
    {children}
  </LayoutContainer>
);

ModalContainer.propTypes = {
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

const ModalPopover = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin-top: 5px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  box-shadow: 15px 15px 60px rgba(0, 21, 46, 0.2);
  z-index: 30;
  transform: ${(props) => (props.isInstallByPopOver ? 'initial' : 'translate(-50%, 0)')};
  left: ${(props) => (props.isInstallByPopOver ? '0' : '50%')};
  top: 46px;
  padding: ${(props) => (props.isInstallByPopOver ? '14px' : '6px')};
  flex-direction: ${(props) => (props.isInstallByPopOver ? 'row' : 'column')};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
`;

export {
  FullscreenModal,
  Modal,
  ModalBody,
  ModalBodyMargin,
  ModalRedSection,
  ModalRedSectionTitle,
  ModalRedSectionText,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalFooterText,
  ModalHeader,
  ModalPopover,
  ModalSubtitleText,
  ModalTitle,
  ModalTitleText,
};
