import React, { useContext, useMemo } from 'react';
import { Form } from 'formik';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { createCustomer } from 'api';
import { useUsageSubscriptionsAPI, getPricingPlansFromSearch } from 'api/usageBasedEngine';
import { HelpCircleIcon } from 'components/Icons';
import { ModalFooter } from 'components/Modal';
import { CancelButton, SaveButton } from 'components/Buttons';
import { Centerer, Flexer, FlexBetweenContainer, FlexEndContainer } from 'components/Core';
import { FormikCustomSelector, CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { InfoIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { FormikDiscountSection } from 'shared/FormikDiscountSection';
import { getCustomerDisplayName } from 'models/customer';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  font-weight: 900;
`;

const FormRow = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
`;

export const SUBSCRIPTION_EDIT_MODAL_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

const InfoTextWrapper = styled(Flexer)`
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background-color: var(--accentGrayFourth);
  margin-bottom: 16px;
`;

const InfoText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack);
`;

const formatDate = (date) => dayjs.utc(date).format('MMM D');

const displayPreviousSubscription = (subscription) =>
  `${formatDate(subscription.start_date)} - ${formatDate(subscription.end_date)} | ${subscription.pricing_plan.name}`;

export const SubscriptionForm = ({
  values,
  setFieldValue,
  handleSubmit,
  getFieldMeta,
  createUsageSubscription,
  editUsageSubscription,
  mode,
  onClose,
}) => {
  const { orgId } = useContext(AppContext);

  const { data: previousSubcriptionsData } = useUsageSubscriptionsAPI({
    orgId,
    autoFetch: !!values?.customer_id,
    params: {
      filters: {
        customerIds: [values?.customer_id],
      },
      pagination: {
        limit: 100,
      },
      scopes: ['pricing_plans'],
    },
  });

  const previousSubscriptionOptions = useMemo(
    () =>
      (previousSubcriptionsData?.data ?? [])
        .filter(
          ({ id, renewal_id }) =>
            [null, values?.id].includes(renewal_id) && id !== values?.id && id !== values?.renewal_id,
        )
        .map((subscription) => ({
          value: subscription.id,
          label: displayPreviousSubscription(subscription),
        })),
    [previousSubcriptionsData, values?.renewal_id, values?.id],
  );

  const handlePreviousSubscriptionChange = (option) => {
    const previousSubscription = (previousSubcriptionsData?.data ?? []).find(({ id }) => id === option?.value);
    setFieldValue('previous_subscription_id', previousSubscription?.id ?? null);
    if (previousSubscription) {
      setFieldValue('pricing_plan_id', previousSubscription.pricing_plan_id);
      setFieldValue('pricing_plan_name', previousSubscription.pricing_plan.name);
      setFieldValue('start_date', previousSubscription.end_date);
      const previousLength = dayjs(previousSubscription.end_date).diff(previousSubscription.start_date, 'month');
      setFieldValue('end_date', dayjs(previousSubscription.end_date).add(previousLength, 'month'));
    }
  };

  return (
    <Form>
      <Wrapper>
        <Header data-cy="edit-subscription-modal__title">
          {mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT ? 'Edit' : 'Create'} Usage-based Subscription
        </Header>

        <FormRow>
          <FlexGrow>
            <FormikCustomSelector
              isDisabled={mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT}
              label="Customer"
              placeholder="Select customer"
              value={
                values?.customer_id
                  ? {
                    label: getCustomerDisplayName({
                      customerName: values?.customer_name,
                      customerId: values?.customer_id,
                    }),
                    value: values?.customer_id,
                  }
                  : null
              }
              name="customer_id"
              handleChange={(option) => {
                if (option) {
                  setFieldValue('customer_id', option.value);
                  setFieldValue('customer_name', option.label);
                } else {
                  setFieldValue('customer_id', null);
                  setFieldValue('customer_name', null);
                }
              }}
              loadOptions={(searchQuery, prevOptions, additional) =>
                getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
              }
              onCreateOption={async (newCustomerName) => {
                const newCustomer = await createCustomer({
                  orgId,
                  customerName: newCustomerName,
                });
                setFieldValue('customer_id', newCustomer.id);
                setFieldValue('customer_name', newCustomer.name);
              }}
              creatable
              isPaginateable
              isClearable
            />
          </FlexGrow>
          <FlexGrow>
            <FormikCustomSelector
              isDisabled={isEmpty(previousSubscriptionOptions)}
              label="Previous subscription"
              placeholder="Select subscription"
              name="previous_subscription_id"
              value={
                values?.previous_subscription_id
                  ? previousSubscriptionOptions.find(({ value }) => value === values.previous_subscription_id)
                  : null
              }
              options={previousSubscriptionOptions}
              handleChange={handlePreviousSubscriptionChange}
              isClearable
            />
          </FlexGrow>
        </FormRow>

        <FormRow>
          <FlexGrow>
            <FormikCustomSelector
              label="Pricing Plan"
              placeholder="Select pricing plan"
              name="pricing_plan_id"
              value={
                values?.pricing_plan_id
                  ? {
                    label: values?.pricing_plan_name,
                    value: values?.pricing_plan_id,
                  }
                  : null
              }
              handleChange={(option) => {
                if (option) {
                  setFieldValue('pricing_plan_id', option.value);
                  setFieldValue('pricing_plan_name', option.label);
                  setFieldValue('currency', option.data?.currency);
                } else {
                  setFieldValue('pricing_plan_id', null);
                  setFieldValue('pricing_plan_name', null);
                  setFieldValue('currency', null);
                }
              }}
              loadOptions={(searchQuery, prevOptions, additional) =>
                getPricingPlansFromSearch({ searchQuery, orgId, prevOptions, additional })
              }
              isPaginateable
              isClearable
            />
          </FlexGrow>
        </FormRow>

        <FormRow>
          <FlexGrow>
            <FormikCustomInput
              name="trial_units"
              data-cy="subscription-create-modal__trial-units"
              label={
                <Centerer>
                  <span>Trial Units</span>
                  {!values?.previous_subscription_id && (
                    <TooltipContainer toolTipContent="We will only start counting after the customer has used up this number of units">
                      <HelpCircleIcon />
                    </TooltipContainer>
                  )}
                </Centerer>
              }
              placeholder="Enter trial units"
              type="number"
              handleChange={(value) => setFieldValue('trial_units', value)}
              isDisabled={!!values?.previous_subscription_id}
            />
          </FlexGrow>
          <CustomDatePicker
            meta={getFieldMeta('start_date')}
            name="start_date"
            label="Start Date"
            selected={values?.start_date ? formatDateForDatepicker(values?.start_date) : null}
            onChange={(value) => setFieldValue('start_date', updateDateFromDatePicker(value))}
          />
          <CustomDatePicker
            meta={getFieldMeta('end_date')}
            name="end_date"
            label="End Date"
            selected={values?.end_date ? formatDateForDatepicker(values?.end_date) : null}
            onChange={(value) => setFieldValue('end_date', updateDateFromDatePicker(value))}
          />
        </FormRow>

        {!!values?.previous_subscription_id && (
          <InfoTextWrapper>
            <InfoIcon size="40px" />
            <InfoText>
              The units paid for but not used from the previous subscription will be used as the trial units of this
              subscription. These units will not be invoiced again, but recognized revenue will be calculated at the
              price point from the previous subscription.
            </InfoText>
          </InfoTextWrapper>
        )}

        <FormikDiscountSection onDiscountUpdated={onClose} />
      </Wrapper>
      <FormFooter>
        <FlexEndContainer>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <SaveButton
            loading={createUsageSubscription.isLoading || editUsageSubscription.isLoading}
            data-cy="usage-subscription-modal__save-button"
            onClick={handleSubmit}
          />
        </FlexEndContainer>
      </FormFooter>
    </Form>
  );
};
