import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { generateInvoicesJSON } from 'api/billing/requests';
import { useClickOutside, useToasts } from 'utils/hooks';
import { RECOGNITION_TYPES } from 'consts/global';
import { FlexBetweenContainer, FlexerColumn, Spacer } from 'components/Core';
import { LightStrokeThumbsUpIcon } from 'components/Icons';
import { PopoverListItem } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';
import { getSuggestedFrequency } from 'views/Billing/utils';
import { BillingContext } from 'views/Billing/BillingContext';
import { useInvoicingSchedulesAPI } from 'api/billing/hooks';

import {
  WhiteButtonScheduleHeader,
  ConfirmationText,
  StyledPopoverWrapper,
  StyledPopover,
  FrequencyOption,
  PopoverItemsSection,
  StyledRefreshIcon,
  ConfirmActionTitle,
  ConfirmationPopoverButton,
} from './styles';
import { usePastAndFutureInvoiceOptions } from './PastAndFutureInvoiceOptions';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

export const InvoicingScheduleFrequencyDropdown = ({
  setFieldValue,
  regenerateInvoices,
  includedTransactions,
  invoices,
  buttonText,
  xOffset,
  iconVersion,
  hasImportedInvoices,
  onSelect,
}) => {
  const generateButtonRef = useRef();

  const { invoicingScheduleFormValues, defaultFrequency, initialOpenRef } = useContext(InvoicingScheduleContext);

  const [ignoreImportInvoiceDates, setIgnoreImportInvoiceDates] = useState(false);

  const [regenerateAfterExistingInvoice, setRegenerateAfterExistingInvoice] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const frequencyForConfirmationRef = useRef();

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);
    setShowConfirmation(false);
  });

  const invoicesCount = invoices?.length ?? 0;
  const hasSentOrVoidedOrPaidInvoices = invoices
    ? invoices?.some((invoice) => invoice.voided_at || invoice.sent_at || invoice.paid_at)
    : false;
  const hasInvoicesInExternalSystem = invoices ? invoices?.some((invoice) => invoice.invoice_external_id) : false;
  const hasInvoicesToNotRegenerate = hasSentOrVoidedOrPaidInvoices || hasInvoicesInExternalSystem;

  const {
    PastAndFutureInvoiceOptions,
    onlyFutureInvoices,
    allowPastInvoices,
    hasInvoicesThatStartInThePast,
    elementsShown,
  } = usePastAndFutureInvoiceOptions({
    includedTransactions,
    hasInvoicesToNotRegenerate,
    regenerateAfterExistingInvoice,
    setRegenerateAfterExistingInvoice,
    otherOptions: [
      {
        label: 'Ignore imported invoice dates',
        onChange: () => {
          setIgnoreImportInvoiceDates((prev) => !prev);
        },
        checked: ignoreImportInvoiceDates,
        tooltipInfo: 'Subscript by default only generates invoices after the latest imported invoice date',
        name: 'billing__invoice-schedule-ignore-import-invoices',
        show: hasImportedInvoices,
        disabled: false,
      },
    ],
  });
  const showPastAndFuture = (hasInvoicesThatStartInThePast || hasImportedInvoices) && !onSelect;

  const hasTillCanceledTransaction = includedTransactions?.some(
    ({ recognition }) => recognition === RECOGNITION_TYPES.tillCanceled,
  );
  const availableFrequencies =
    hasTillCanceledTransaction || onSelect
      ? [
          INVOICING_FREQUENCIES.MONTHLY,
          INVOICING_FREQUENCIES.QUARTERLY,
          INVOICING_FREQUENCIES.SEMI_ANNUALLY,
          INVOICING_FREQUENCIES.ANNUALLY,
        ]
      : Object.values(INVOICING_FREQUENCIES);

  const toggleDropdown = (event) => {
    const buttonRect = generateButtonRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - buttonRect.bottom;

    if (spaceBelow < 350) {
      setDropDownYOffset(
        -39 -
          10 * (elementsShown > 0 && showPastAndFuture) -
          46 * (elementsShown * showPastAndFuture) -
          41 * availableFrequencies.length,
      );
    } else {
      setDropDownYOffset(50);
    }

    event?.preventDefault();
    setShowDropdown(!showDropdown);
  };
  const [dropDownYOffset, setDropDownYOffset] = useState(50); // by default below

  const handleOnClickFrequency = (frequency) => {
    setFieldValue('invoicing_frequency', frequency);
    toggleDropdown();
    if (onSelect) {
      onSelect({ frequency });
    } else {
      regenerateInvoices({
        frequency,
        onlyFutureInvoices,
        allowPastInvoices,
        ignoreImportInvoiceDates,
        regenerateAfterExistingInvoice: !allowPastInvoices && regenerateAfterExistingInvoice,
        hasInvoicesToNotRegenerate,
      });
    }
  };

  const suggestedFrequency = getSuggestedFrequency({ transactions: includedTransactions });

  useEffect(() => {
    if (invoices?.length === 0 && invoicingScheduleFormValues?.customer_id && initialOpenRef.current) {
      regenerateInvoices({ frequency: defaultFrequency, allowPastInvoices: true });
      setFieldValue('invoicing_frequency', defaultFrequency);
      initialOpenRef.current = false;
    }
  }, [
    defaultFrequency,
    initialOpenRef,
    invoices,
    invoicingScheduleFormValues?.customer_id,
    regenerateInvoices,
    setFieldValue,
  ]);

  return (
    <>
      <StyledPopoverWrapper ref={dropdownRef}>
        <TooltipContainer
          toolTipContent={
            includedTransactions?.length === 0
              ? 'Select transactions first'
              : invoicesCount > 0
              ? 'This will undo all of your manual changes of the invoices'
              : ''
          }
          width={includedTransactions?.length === 0 ? 180 : 220}
          isVisible={
            hasInvoicesToNotRegenerate || showDropdown ? false : includedTransactions?.length === 0 || invoicesCount > 0
          }
          tooltipWrapperStyles={{
            width: '100%',
          }}
        >
          <WhiteButtonScheduleHeader
            data-cy="billing__invoice-schedule-modal__schedule-generate-button"
            onClick={toggleDropdown}
            disabled={includedTransactions?.length === 0 || showConfirmation}
            ref={generateButtonRef}
          >
            {iconVersion ? <StyledRefreshIcon /> : <span>{buttonText ?? 'Regenerate'}</span>}
          </WhiteButtonScheduleHeader>
        </TooltipContainer>
        {showDropdown && (
          <StyledPopover
            YOffset={dropDownYOffset}
            XOffset={xOffset ?? 100}
            width={hasInvoicesToNotRegenerate ? '300px' : null}
          >
            {showPastAndFuture && <PopoverItemsSection>{PastAndFutureInvoiceOptions}</PopoverItemsSection>}

            <PopoverItemsSection>
              {availableFrequencies.map((frequency) => (
                <PopoverListItem
                  data-cy={`billing__invoice-schedule-modal__schedule-generate-button--${frequency}`}
                  key={frequency}
                  onClick={() => {
                    if (hasInvoicesToNotRegenerate) {
                      frequencyForConfirmationRef.current = frequency;
                      setShowDropdown(false);
                      setShowConfirmation(true);
                    } else {
                      handleOnClickFrequency(frequency);
                    }
                  }}
                >
                  <FrequencyOption>
                    <div>{frequency}</div>
                    {frequency === suggestedFrequency ? (
                      <TooltipContainer toolTipContent="We recommend this billing frequency based on the transactions you selected">
                        <LightStrokeThumbsUpIcon size="16px" />
                      </TooltipContainer>
                    ) : null}
                  </FrequencyOption>
                </PopoverListItem>
              ))}
            </PopoverItemsSection>
          </StyledPopover>
        )}
        {showConfirmation && (
          <StyledPopover YOffset={50} XOffset={50} width="220px">
            <FlexerColumn justifyContent="center" alignItems="center">
              <ConfirmActionTitle>CONFIRM ACTION</ConfirmActionTitle>

              <Spacer height="16px" />

              <ConfirmationText fontStyle="italic" fontWeight={700}>
                Do you want to regenerate only{' '}
                <ConfirmationText fontStyle="italic" fontWeight={700} highlight>
                  unsent draft
                </ConfirmationText>{' '}
                invoices?
              </ConfirmationText>

              <Spacer height="4px" />

              <ConfirmationText subText>
                All invoices that have restrictions would stay exactly as they are.
              </ConfirmationText>

              <Spacer height="8px" />

              <FlexBetweenContainer gap="8px">
                <ConfirmationPopoverButton onClick={() => setShowConfirmation(false)}>Cancel</ConfirmationPopoverButton>
                <ConfirmationPopoverButton
                  isConfirmButton={true}
                  onClick={() => handleOnClickFrequency(frequencyForConfirmationRef.current)}
                  data-cy="billing__invoice-schedule-modal__schedule-generate-button--confirm"
                >
                  Confirm
                </ConfirmationPopoverButton>
              </FlexBetweenContainer>
            </FlexerColumn>
          </StyledPopover>
        )}
      </StyledPopoverWrapper>
    </>
  );
};

export const useInvoicingScheduleFrequencyDropdown = ({ onSelect, invoices, setFieldValue, importedInvoices }) => {
  const { orgId } = useContext(AppContext);
  const {
    invoicingScheduleFormValues,
    fillScheduleWithDraftInvoices,
    setSelectedInvoiceId,
    setFetchedSelectedInvoice,
    setExpectedNumberOfInvoices,
    includedTransactions,
    isScheduleDraft,
  } = useContext(InvoicingScheduleContext);

  const { refetchInvoicingScheduleRef } = useContext(BillingContext);

  const {
    operations: { regenerateInvoicingScheduleDraftInvoices },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });

  const { pushError } = useToasts();

  const [loading, setLoading] = useState(false);

  const [generatedInvoicesExplanations, setGeneratedInvoicesExplanations] = useState([]);

  const regenerateInvoices = useCallback(
    async ({
      frequency,
      allowPastInvoices = false,
      onlyFutureInvoices = false,
      ignoreImportInvoiceDates = false,
      hasInvoicesToNotRegenerate,
      regenerateAfterExistingInvoice,
    }) => {
      setLoading(true);

      // TO BE REMOVED:This logic here is to adapt to the new backend changes before the frontend is modified
      const adjustInvoicingScheduleFormValues = (invoicingSchedule) => {
        const transactionOptions = Object.entries(invoicingSchedule?.transaction_options).reduce(
          (acc, [transactionId, transactionOption]) => {
            if (!allowPastInvoices) transactionOption.allowPastInvoices = allowPastInvoices;
            if (onlyFutureInvoices) transactionOption.onlyFutureInvoices = onlyFutureInvoices;
            return {
              ...acc,
              [transactionId]: transactionOption,
            };
          },
          {},
        );

        return {
          ...invoicingSchedule,
          transaction_options: transactionOptions,
        };
      };

      try {
        if (hasInvoicesToNotRegenerate && !isScheduleDraft) {
          const updatedInvoicingSchedule = await regenerateInvoicingScheduleDraftInvoices.mutateAsync({
            data: {
              invoicingSchedule: adjustInvoicingScheduleFormValues(invoicingScheduleFormValues),
              frequency,
              allowPastInvoices,
              onlyFutureInvoices,
              externalInvoices: importedInvoices,
              ignoreImportInvoiceDates,
              currency: invoicingScheduleFormValues?.currency,
              regenerateAfterExistingInvoice,
            },
          });
          await refetchInvoicingScheduleRef.current();

          setSelectedInvoiceId(updatedInvoicingSchedule.invoices[0].id);
        } else {
          const { data, invoicingSchedule, metadata } = await generateInvoicesJSON({
            orgId,
            body: {
              customerId: invoicingScheduleFormValues?.customer_id ?? includedTransactions?.[0]?.customer_id,
              transactionIds: includedTransactions.map(({ id }) => id),
              invoicingSchedule: adjustInvoicingScheduleFormValues(invoicingScheduleFormValues),
              frequency: frequency ?? invoicingScheduleFormValues?.invoicing_frequency,
              allowPastInvoices,
              todayForClient: dayjs().format('YYYY-MM-DD'),
              currency: invoicingScheduleFormValues?.currency,
              onlyFutureInvoices,
              externalInvoices: importedInvoices,
              ignoreImportInvoiceDates,
            },
            params: {
              includeExplanation: true,
            },
          });

          setGeneratedInvoicesExplanations(metadata?.explanations ?? []);

          setExpectedNumberOfInvoices(data?.length);

          const invoices = data.map((rawInvoice) => ({
            ...rawInvoice,
            unsavedId: uuidv4(),
            invoice_items: rawInvoice.invoice_items?.map((item) => ({ id: Math.random() * 100000, ...item })),
          }));

          await fillScheduleWithDraftInvoices({
            invoices,
            invoicingSchedule,
            frequency: frequency ?? invoicingScheduleFormValues?.invoicing_frequency,
            allowPastInvoices,
          });

          setSelectedInvoiceId(null);
          setFetchedSelectedInvoice(null);
        }
      } catch (err) {
        pushError(err, 'Failed to regenerate invoices');
        console.error({ message: err.message, component: 'InvoicingScheduleFrequencyDropdown', stack: err });
      }
      setLoading(false);
    },
    [
      fillScheduleWithDraftInvoices,
      refetchInvoicingScheduleRef,
      importedInvoices,
      includedTransactions,
      invoicingScheduleFormValues,
      orgId,
      pushError,
      regenerateInvoicingScheduleDraftInvoices,
      setExpectedNumberOfInvoices,
      setFetchedSelectedInvoice,
      setSelectedInvoiceId,
      isScheduleDraft,
    ],
  );

  const DropDown = ({ buttonText, xOffset, iconVersion }) => (
    <InvoicingScheduleFrequencyDropdown
      buttonText={buttonText}
      xOffset={xOffset}
      iconVersion={iconVersion}
      setFieldValue={setFieldValue}
      regenerateInvoices={regenerateInvoices}
      includedTransactions={includedTransactions}
      invoices={invoices}
      hasImportedInvoices={importedInvoices && importedInvoices.length > 0}
      onSelect={onSelect}
    />
  );

  return {
    InvoicingScheduleFrequencyDropdown: DropDown,
    isLoading: loading,
    generatedInvoicesExplanations,
  };
};
