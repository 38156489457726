import { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from 'AppContext';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'components/Toasts';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { useInvoicesAPI } from 'api/billing';
import { useIntegrationsAPI } from 'api/integrations';
import { useOrganizationCountsAPI, useOrganizationMetadataAPI } from 'api/organizations';
import { useBillingEmailTemplatesAPI, useBillingRemindersAPI, useInvoiceAPI } from 'api/billing/hooks';
import { getUrlParams } from 'utils/urlUtils';
import { PAGE_CONTEXT_VALUES, usePageContext, useStateWithStorage } from 'utils/hooks';
import { PERIOD_OPTIONS, onSelectPeriod } from 'shared/Filters';
import { INVOICE_STATUS_TOGGLES } from 'shared/Filters/InvoicesFilter/InvoicesFilter';
import { BillingContext } from './BillingContext';
import { useInvoicingScheduleModal } from './InvoicingScheduleModal';
import {
  markInvoiceAsVoid,
  markInvoiceAsPaid,
  markInvoiceAsUnpaid,
  markInvoiceAsSent,
  testSendInvoice,
  sendInvoice,
  saveInvoiceWithPreview,
  editFutureInvoices,
  handleRemindSend,
  resetInvoiceMemo,
  resetInvoiceSecondaryMemo,
  bulkResetInvoicesSecondaryMemo,
  resetInvoiceEmailSubject,
  resetInvoiceEmailBody,
  updateInvoice,
  handleRemindEdit,
  testSendReminder,
  bulkResetInvoicesMemo,
} from './submitUtils';
import { INVOICE_STATUSES } from './consts';
import { USAGE_BASED_ENGINE_TABS } from './UsageBasedEngine/Tabs/consts';

export const INVOICE_FILTER_TO_STATUS = {
  [INVOICE_STATUSES.ALL_FAILED]: [
    INVOICE_STATUSES.PAYMENT_FAILED,
    INVOICE_STATUSES.SAVE_FAILED,
    INVOICE_STATUSES.SENT_FAILED,
  ],
  [INVOICE_STATUSES.ALL_PROCESSING]: [
    INVOICE_STATUSES.QUEUED_UP,
    INVOICE_STATUSES.PROCESSING,
    INVOICE_STATUSES.TAXES_PROCESSING,
  ],
  [INVOICE_STATUSES.PROCESSING]: [INVOICE_STATUSES.PROCESSING, INVOICE_STATUSES.TAXES_PROCESSING],
};

export const INVOICES_FILTER_GROUP_BY = {
  MONTH: 'Month',
  CUSTOMER: 'Customer',
  INVOICING_SCHEDULE: 'Invoicing schedule',
};

export const BillingProvider = ({ children, fetchBillingReminders = true }) => {
  const { orgId } = useContext(AppContext);
  const { data } = useOrganizationCountsAPI({ orgId, scopes: ['transactionsThatNeedSchedules'] });
  const transactionsThatNeedSchedules = data?.transactionsThatNeedSchedules ?? 0;

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();

  const [invoiceStatusFilter, setInvoiceStatusFilter] = useStateWithStorage('invoices-table__status-filter', [
    INVOICE_STATUS_TOGGLES.map((status) => status.value),
  ]);
  const [invoiceStatuses, setInvoiceStatuses] = useState(
    INVOICE_STATUS_TOGGLES.map((status) => INVOICE_FILTER_TO_STATUS[status.value] ?? status.value).flat(),
  );
  const [invoicesGroupBy, setInvoicesGroupBy] = useState(INVOICES_FILTER_GROUP_BY.MONTH);

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { earliestAndLatestSpreadsDates } = organizationMetadata ?? {};
  const { urlState } = usePageContext({ page: PAGE_CONTEXT_VALUES.invoices_list.key });
  const [billingDataFilter, setBillingDataFilter] = useStateWithStorage('invoices-table__filters', {
    ...urlState?.dataFilter,
    period: PERIOD_OPTIONS.allTime,
    startMonth: onSelectPeriod({ period: PERIOD_OPTIONS.allTime, earliestAndLatestSpreadsDates })?.startMonth,
    endMonth: onSelectPeriod({ period: PERIOD_OPTIONS.allTime, earliestAndLatestSpreadsDates })?.endMonth,
  });
  const [invoicesSearchQuery, setInvoicesSearchQuery] = useState('');

  const {
    operations: { refetch: refetchInvoices, invalidateAllInvoices: forceRefetchInvoices },
  } = useInvoicesAPI({
    orgId,
    autoFetch: false,
  });

  const {
    operations: {
      editInvoice: _editInvoice,
      createInvoice,
      bulkResetMemo,
      bulkResetSecondaryMemo,
      removeInvoice,
      bulkEditInvoices,
      bulkDetachInvoices,
      bulkDeleteInvoices,
      regenerateInvoicePDF,
    },
  } = useInvoiceAPI({
    orgId,
    autoFetch: false,
  });

  const {
    data: reminders,
    isFetching: isFetchingReminders,
    operations: {
      refetch: refetchReminders,
      editBillingEmailReminder,
      enableBillingAIReminders,
      bulkRemoveReminders,
      bulkUpdateReminders,
      removeBillingEmailReminder,
      dismissReminder,
    },
  } = useBillingRemindersAPI({
    orgId,
    autoFetch: fetchBillingReminders,
  });

  const {
    data: emailTemplates,
    operations: { editBillingEmailTemplate },
  } = useBillingEmailTemplatesAPI({ orgId });

  const { data: disconnectedIntegrations } = useIntegrationsAPI({
    orgId,
    params: {
      includeProducts: false,
      onlyDisauthenticated: true,
    },
  });
  const glDisconnectedIntegrations = disconnectedIntegrations?.filter(
    (integration) => integration.type === INTEGRATION_TYPES.GL,
  );

  const { pushToast } = useToasts();

  const { state } = useLocation();

  const query = getUrlParams();
  const currentUsageBasedEngineTab = query?.tab ?? USAGE_BASED_ENGINE_TABS.USAGE_SUBSCRIPTIONS;

  useEffect(() => {
    if (state) {
      if (state?.status !== undefined) {
        setInvoiceStatusFilter(
          state.status === INVOICE_STATUSES.ALL ? INVOICE_STATUS_TOGGLES.map((status) => status.value) : [state.status],
        );
      }
      if (state?.startMonth !== undefined && state?.endMonth !== undefined) {
        setBillingDataFilter((prevState) => ({
          ...prevState,
          period: state?.period ?? PERIOD_OPTIONS.custom,
          startMonth: state?.startMonth,
          endMonth: state?.endMonth,
        }));
      }
    }
  }, [setInvoiceStatusFilter, setBillingDataFilter, state]);

  useEffect(() => {
    setInvoiceStatuses(invoiceStatusFilter.map((filter) => INVOICE_FILTER_TO_STATUS[filter] ?? filter).flat());
  }, [invoiceStatusFilter]);

  const openInvoiceModal = ({ invoice, initialTab }) =>
    openInvoicingScheduleModal({
      invoicingSchedule: { id: invoice?.invoicing_schedule_id },
      invoice,
      selectedReminder: invoice?.selectedReminder,
      initialTab,
    });

  const refetchInvoicingScheduleRef = useRef(() => {});

  const wrapWithRefetch = (func) => async (params) => {
    const result = await func(params);
    await refetchInvoicingScheduleRef.current();
    return result;
  };

  const editInvoice = wrapWithRefetch(_editInvoice);

  return (
    <BillingContext.Provider
      value={{
        metadataFilter: urlState?.metadataFilter,
        setMetadataFilter: urlState?.setMetadataFilter,
        emailTemplates,
        editBillingEmailTemplate,
        reminders,
        isFetchingReminders,
        editBillingEmailReminder,
        bulkRemoveReminders,
        bulkUpdateReminders,
        enableBillingAIReminders,
        removeBillingEmailReminder,
        refetchReminders,
        dismissReminder,
        openInvoiceModal,
        openInvoicingScheduleModal,
        invoiceStatusFilter,
        setInvoiceStatusFilter,
        invoiceStatuses,
        setInvoiceStatuses,
        invoicesGroupBy,
        setInvoicesGroupBy,
        billingDataFilter,
        setBillingDataFilter,
        invoicesSearchQuery,
        setInvoicesSearchQuery,
        refetchInvoices,
        forceRefetchInvoices,

        pushToast,
        transactionsThatNeedSchedules,

        editInvoice,
        removeInvoice: wrapWithRefetch(removeInvoice),
        createInvoice: wrapWithRefetch(createInvoice),
        bulkEditInvoices: wrapWithRefetch(bulkEditInvoices),
        bulkDetachInvoices,
        bulkDeleteInvoices: wrapWithRefetch(bulkDeleteInvoices),
        regenerateInvoicePDF,
        currentUsageBasedEngineTab,

        markInvoiceAsVoid: markInvoiceAsVoid({ editInvoice }),
        markInvoiceAsPaid: markInvoiceAsPaid({ editInvoice }),
        markInvoiceAsUnpaid: markInvoiceAsUnpaid({ editInvoice }),
        markInvoiceAsSent: markInvoiceAsSent({ editInvoice }),
        testSendInvoice: testSendInvoice({ editInvoice, pushToast }),
        sendInvoice: sendInvoice({ editInvoice }),
        saveInvoiceWithPreview: saveInvoiceWithPreview({ editInvoice }),
        editFutureInvoices: editFutureInvoices({ editInvoice, pushToast }),
        updateInvoice: updateInvoice({ editInvoice }),
        resetInvoiceMemo: resetInvoiceMemo({ editInvoice, pushToast }),
        bulkResetInvoicesMemo: bulkResetInvoicesMemo({ bulkResetMemo }),
        resetInvoiceSecondaryMemo: resetInvoiceSecondaryMemo({ editInvoice, pushToast }),
        bulkResetInvoicesSecondaryMemo: bulkResetInvoicesSecondaryMemo({ bulkResetSecondaryMemo }),
        resetInvoiceEmailSubject: resetInvoiceEmailSubject({ editInvoice, pushToast }),
        resetInvoiceEmailBody: resetInvoiceEmailBody({ editInvoice, pushToast }),

        handleRemindSend: handleRemindSend({ editBillingEmailReminder }),
        handleRemindEdit: handleRemindEdit({ editBillingEmailReminder }),
        testSendReminder: testSendReminder({ editBillingEmailReminder }),

        refetchInvoicingScheduleRef,

        glDisconnectedIntegrations,
      }}
    >
      {children}
      <InvoicingScheduleModal />
    </BillingContext.Provider>
  );
};
