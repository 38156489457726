import { useCallback, useContext, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { RECOGNITION_TYPES } from 'consts/global';
import { hasAutoChargeSupport } from 'views/Billing/utils';
import { SwitchWithLabel } from 'components/Controls';
import { ReactComponent as BillingEmptyInvoicesIcon } from 'images/billing-empty-invoices.svg';
import { Centerer, Flexer, FlexerColumn, Spacer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { DIRECTIONS, Popover, PopoverActions, PopoverButton, PopoverPrompt, PopoverWrapper } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { pluralize } from 'utils/stringUtils';
import { EditCircleIcon, WarningIcon } from 'components/Icons';
import { PRODUCT_IMPORT_METADATA_KEYS, SERVICE_WITH_INCOME_ACCOUNT_REF } from 'views/Billing/consts';
import { BillingEntitySelector } from 'views/Billing/Common/BillingEntitySelector';

import { AmountsList } from './AmountsList';
import { PanelContainer, SectionWrapper, StyledText, SubStyledText, Container, Section } from './styles';
import { useInvoicingScheduleFrequencyDropdown } from '../InvoicingScheduleTabsPanel/useInvoicingScheduleFrequencyDropdown';
import { getSortedInvoices } from '../utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { InvoicingScheduleSelectImportInvoicesPanel } from '../InvoicingScheduleSelectImportInvoicesPanel';
import { InvoicingScheduleLanguageSelector } from '../InvoicingScheduleLanguageSelector';
import { useInvoiceCurrencySwitch } from './useInvoiceCurrencySwitch';
import { useExternalInvoiceTemplateSelector } from '../useExternalInvoiceTemplateSelector';
import { useSelectTransactionModal } from '../InvoicingScheduleSelectTransactionsPanel';
import { IntegrationSelector } from '../IntegrationSelector';

const HeaderSubTextWrapper = styled(Centerer)`
  width: 240px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--primaryBlack3);
  position: absolute;
  left: 0;
  right: 0;
  top: 38px;
`;

const WarningText = styled.div`
  font-size: 12px;
  font-style: italic;
  color: var(--tertiaryRed);
`;

const TABS = {
  TRANSACTIONS: 'transactions',
  INVOICES: 'invoices',
};

export const InvoicingScheduleCreatePanel = () => {
  const { integrations, orgConfigs } = useContext(AppContext);

  const { incomeAccountRefId } = orgConfigs;

  const {
    includedTransactions,
    invoicingScheduleFormValues,
    invoicingService,
    customerDetails,
    isCustomerDetailsLoading,
    glIntegration,
    productImportsByProductId,
  } = useContext(InvoicingScheduleContext);
  const { setFieldValue, values } = useFormikContext();

  const { InvoiceCurrencySwitch } = useInvoiceCurrencySwitch({ setFieldValue, values });

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const [importedInvoices, setImportedInvoices] = useState([]);

  const { InvoicingScheduleFrequencyDropdown, isLoading: isGeneratingInvoices } = useInvoicingScheduleFrequencyDropdown(
    {
      invoices,
      setFieldValue,
      importedInvoices,
    },
  );

  const { Modal: SelectTransactionModal, openModal: openSelectTransactionModal } = useSelectTransactionModal();

  const [selectedTab, setSelectedTab] = useState(null);

  const showIncludedTransactionsPanel = selectedTab === TABS.TRANSACTIONS;
  const showImportedInvoicesPanel = selectedTab === TABS.INVOICES;

  const hasActivePaymentMethod = !!customerDetails?.has_active_payment_method;

  const hasAutoCharge = useMemo(() => hasAutoChargeSupport({ integrations }), [integrations]);

  const hasTillCanceledTransaction = useMemo(
    () => includedTransactions?.some(({ recognition }) => recognition === RECOGNITION_TYPES.tillCanceled),
    [includedTransactions],
  );

  const handleAutoChargeClick = useCallback(
    (value) => {
      setFieldValue('auto_charge', value);
      setFieldValue('auto_send', false);
    },
    [setFieldValue],
  );

  const handleTabSwitch = (tab) => setSelectedTab((prev) => (prev === tab ? null : tab));

  const { ExternalInvoiceTemplateSelector, hasInvoiceTemplates } = useExternalInvoiceTemplateSelector({
    name: 'external_invoice_template_id',
    integrationId: glIntegration?.id,
  });

  const showAutoChargeCallToAction =
    hasAutoCharge &&
    (hasTillCanceledTransaction || hasActivePaymentMethod) &&
    [null, undefined].includes(invoicingScheduleFormValues?.auto_charge) &&
    !isCustomerDetailsLoading;

  const AutoCharge = () => (
    <SwitchWithLabel
      data-cy="billing__invoice-schedule-modal__auto-charge-checkbox"
      name="auto_charge"
      label="Ask customer to pay by credit card with auto-charge for future invoices"
      labelSize="12px"
      disabled={!hasAutoCharge}
      onChange={handleAutoChargeClick}
      checked={values.auto_charge}
      alignItems="flex-start"
    />
  );

  const shouldSetupIncomeAccount =
    SERVICE_WITH_INCOME_ACCOUNT_REF.includes(invoicingService) &&
    !(
      incomeAccountRefId ||
      includedTransactions.every(
        (transaction) =>
          productImportsByProductId[transaction?.product_id]?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT],
      )
    );

  return (
    !invoicingScheduleFormValues?.invoices?.length &&
    !invoicingScheduleFormValues?.id && (
      <Container>
        <PanelContainer
          isSidePanelOpened={!!selectedTab}
          isDisabled={!invoicingScheduleFormValues?.customer_id}
          isLoading={isGeneratingInvoices}
        >
          {isGeneratingInvoices ? (
            <CircleLoader name="invoices" width={48} height={48} />
          ) : (
            <>
              <FlexerColumn gap="16px" alignItems="center">
                <BillingEmptyInvoicesIcon />
                <StyledText fontSize="14px" fontWeight={900}>
                  Create a schedule
                </StyledText>
                <HeaderSubTextWrapper>
                  <SubStyledText textAlign="center">
                    Use your billing frequency to generate invoices and to not miss anything
                  </SubStyledText>
                </HeaderSubTextWrapper>
              </FlexerColumn>

              <FlexerColumn gap="8px">
                <Flexer gap="8px">
                  <SectionWrapper
                    selected={showIncludedTransactionsPanel}
                    onClick={(event) => {
                      event?.preventDefault();
                      openSelectTransactionModal({ isCreatePanel: true });
                    }}
                    data-cy="billing__invoice-schedule-modal__open-transactions-list-button"
                  >
                    <Section>
                      <div>
                        <StyledText color="var(--primaryBlue)" fontWeight={700} fontStyle="italic">
                          {pluralize(includedTransactions.length, 'transaction')}
                        </StyledText>{' '}
                        <StyledText fontWeight={700} fontStyle="italic">
                          included
                        </StyledText>
                      </div>
                      <EditCircleIcon fill="var(--primaryBlack)" size="16px" />
                    </Section>

                    {shouldSetupIncomeAccount && (
                      <>
                        <Line />

                        <Flexer gap="4px" padding="4px">
                          <WarningIcon size="16px" />
                          <WarningText> First set up income accounts for all transactions / products</WarningText>
                        </Flexer>
                      </>
                    )}
                  </SectionWrapper>

                  <SectionWrapper
                    selected={showImportedInvoicesPanel}
                    onClick={() => handleTabSwitch(TABS.INVOICES)}
                    data-cy="billing__invoice-schedule-modal__open-external-invoices-list-button"
                  >
                    <Section>
                      <div>
                        <StyledText color="var(--primaryBlue)" fontWeight={700} fontStyle="italic">
                          {pluralize(importedInvoices.length, 'invoice')}
                        </StyledText>{' '}
                        <StyledText fontWeight={700} fontStyle="italic">
                          imported
                        </StyledText>
                      </div>
                      <EditCircleIcon fill="var(--primaryBlack)" size="16px" />
                    </Section>
                  </SectionWrapper>
                </Flexer>

                <SectionWrapper bgcolor="transparent" disableActions>
                  <AmountsList importedInvoices={importedInvoices} />
                </SectionWrapper>
              </FlexerColumn>

              <FlexerColumn gap="8px" marginTop="4px">
                <BillingEntitySelector name="entity_id" value={invoicingScheduleFormValues?.entity_id} />

                <InvoicingScheduleLanguageSelector />

                <Flexer gap="8px">
                  <IntegrationSelector />

                  {hasInvoiceTemplates && <ExternalInvoiceTemplateSelector containerWidth="110%" />}
                </Flexer>

                <SwitchWithLabel
                  name="schedule-auto-send"
                  onChange={(value) => setFieldValue('auto_send', value)}
                  checked={invoicingScheduleFormValues?.auto_send}
                  label="Send all invoices automatically"
                  disabled={invoicingScheduleFormValues?.auto_charge === true}
                  labelSize="12px"
                />

                <PopoverWrapper>
                  {hasAutoCharge ? (
                    <AutoCharge />
                  ) : (
                    <TooltipContainer toolTipContent="You need to integrate with Stripe to enable this feature. Please integrate with Stripe via Settings/Add Integration, or contact us for support.">
                      <AutoCharge />
                    </TooltipContainer>
                  )}
                  {showAutoChargeCallToAction && (
                    <Popover
                      XOffset={150}
                      YOffset={45}
                      zIndex={51}
                      darkMode
                      width={hasActivePaymentMethod ? '320px' : '300px'}
                      showArrow
                      arrowDirection={DIRECTIONS.TOP}
                    >
                      <PopoverPrompt>
                        {hasActivePaymentMethod
                          ? 'This customer already has an active payment method on Stripe. We recommend enabling Auto-charge to make the payment process more seamless.'
                          : 'Do you want to auto-charge customers via Stripe?'}
                      </PopoverPrompt>
                      <PopoverActions>
                        <PopoverButton onClick={() => setFieldValue('auto_charge', false)}>No</PopoverButton>
                        <PopoverButton onClick={() => setFieldValue('auto_charge', true)} primary>
                          Yes
                        </PopoverButton>
                      </PopoverActions>
                    </Popover>
                  )}
                </PopoverWrapper>

                {showAutoChargeCallToAction && <Spacer height={hasActivePaymentMethod ? '120px' : '80px'} />}

                <InvoiceCurrencySwitch />
              </FlexerColumn>

              <Flexer marginTop="20px" justifyContent="center" width="100%">
                <Flexer width="200px">
                  <InvoicingScheduleFrequencyDropdown buttonText="Generate Invoices" />
                </Flexer>
              </Flexer>
            </>
          )}
        </PanelContainer>

        <InvoicingScheduleSelectImportInvoicesPanel
          isOpen={showImportedInvoicesPanel && !isGeneratingInvoices}
          onClose={() => handleTabSwitch()}
          importedInvoices={importedInvoices}
          onRemoveInvoice={(selectedInvoice) =>
            setImportedInvoices(
              importedInvoices.filter((invoice) => invoice.external_id !== selectedInvoice.external_id),
            )
          }
          onImportInvoicesSelect={(value) => setImportedInvoices(value)}
        />

        <SelectTransactionModal />
      </Container>
    )
  );
};
