export const FILE_TYPES = {
  CSV: ['text/csv'],
  IMAGES: ['image/png', 'image/jpg', 'image/jpeg'],
  PDF: ['application/pdf'],
  DOCS: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/markdown',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/plain',
  ],
  ALL: ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg', 'text/csv'],
};

export const FILE_SIGNATURES = {
  'application/pdf': 'JVBERi0',
  'image/jpeg': '/9j/',
  'image/png': 'iVBORw0KGgo',
  'image/jpg': '/9j/',
  'text/csv': 'data:text/csv',
  'application/msword': 'D0CF11E0',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'PK\x03\x04',
  'text/markdown': 'data:text/markdown',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PK\x03\x04',
  'application/vnd.ms-powerpoint': 'D0CF11E0',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'PK\x03\x04',
  'application/vnd.ms-excel': 'D0CF11E0',
  'text/plain': 'data:text/plain',
};

export const FILE_TYPES_NAMES = {
  'text/csv': 'csv',
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/plain': 'txt',
  'text/markdown': 'md',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
};

export const ALLOWED_MIME_TYPES = Object.keys(FILE_TYPES_NAMES);

export const ALLOWED_EXTENSIONS = Object.values(FILE_TYPES_NAMES);

export const DEFAULT_MAX_FILE_SIZE = 5242880;
