import { TooltipContainer } from 'components/Tooltip';
import { ALREADY_SELECTED_GROUP_NAME } from '../utils';
import { MISSING_EXTERNAL_ID_GROUP } from '../consts';
import { TransactionLineItem } from './TransactionLineItem';
import { ExternalId, TransactionItemWrapper, TransactionsGroup } from './styles';

export const SelectTransactionsTable = ({
  groupedTransactions,
  isNotCreatedSchedule,
  currentInvoicingSchedule,
  initiallySelectedTransactions,
  isCreatePanel,
}) => {
  const renderTransaction = (transaction, index) => (
    <TooltipContainer
      key={transaction.id}
      width={200}
      isVisible={!transaction.product_id}
      toolTipContent="Transaction without product"
    >
      <TransactionItemWrapper
        data-cy={`select-transactions-table__selected-transaction`}
        key={transaction.id}
        disabled={!transaction.product_id}
        hasBorder={index > 0}
      >
        <TransactionLineItem
          isNotCreatedSchedule={isNotCreatedSchedule}
          transaction={transaction}
          disabled={
            !transaction.product_id ||
            (!isCreatePanel && initiallySelectedTransactions.some((t) => t.id === transaction.id))
          }
          showCheckbox
          invoicingScheduleFrequency={currentInvoicingSchedule?.invoicing_frequency}
        />
      </TransactionItemWrapper>
    </TooltipContainer>
  );

  return Object.entries(groupedTransactions).map(([externalId, transactions], index) => (
    <TransactionsGroup hasBorder={index > 0} key={externalId}>
      <ExternalId>
        <span>
          {externalId === MISSING_EXTERNAL_ID_GROUP
            ? 'Manually created contracts'
            : externalId === ALREADY_SELECTED_GROUP_NAME
            ? ALREADY_SELECTED_GROUP_NAME
            : isNaN(externalId)
            ? `Contract ${externalId}`
            : `Contract #${externalId}`}
        </span>
      </ExternalId>
      {transactions.map((transaction, index) => renderTransaction(transaction, index))}
    </TransactionsGroup>
  ));
};
