import React, { useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { COLORS } from 'consts/colors';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { DEFAULT_SEGMENTS, getConfigsFromData } from 'views/Wizard/utils';
import { METADATA_SEGMENT_OBJECT_TYPES } from 'shared/Filters/MetadataFilter/consts';
import { formatMetadataSegments, valuesToLevels } from 'shared/MetadataSegmentsModal/utils';
import { getAppSetting } from 'models/appSettings/base';
import { useUrlQuery } from 'utils/hooks';
import { useIntegrationsAPI } from 'api/integrations/hooks';
import { getIntegrationsObjectFields } from 'api/integrations';
import { useConfigAPI } from 'api/configs';
import { useAnalyticsIntegrations } from 'shared/ReportSubscribe';
import { ReactComponent as SaveIcon } from 'images/device-floppy.svg';
import { Loader } from 'components/Loaders';
import { Centerer } from 'components/Core';
import { SubscriptionData } from 'views/Wizard/steps/SubscriptionData/SubscriptionData';
import { APIKeyCard, NotificationsCard } from '../Common';
import { StyledIconButton, StyledHeaderIconButton, BodyContainer, ConfigError } from '../styles';
import { LocaleRegionCard } from './LocaleRegionCard';

export const GeneralSettingsTab = () => {
  const {
    organizations,
    orgConfigs,
    appSettings: {
      isARR: appIsARR,
      isCommitted: appIsCommitted,
      influxMonths: appInfluxMonths,
      currencyISOCode: appCurrencyISOCode,
      setCurrencyISOCode: appSetCurrencyISOCode,
    },
  } = useContext(AppContext);

  // this will be in the url if an integration link fails
  const query = useUrlQuery();
  const error = query.get('error');

  const appCommittedPolicy = getAppSetting('committedPolicy');
  const appCommittedStart = getAppSetting('committedStart');
  const appQuarters = getAppSetting('quarters');

  const [isARR, setIsARR] = useState();
  const [isCommitted, setIsCommitted] = useState();
  const [currencyISOCode, setCurrencyISOCode] = useState(appCurrencyISOCode);
  const [committedPolicy, setCommittedPolicy] = useState();
  const [committedStart, setCommittedStart] = useState();
  const [influxMonths, setInfluxMonths] = useState();
  const [influxOption, setInfluxOption] = useState();
  const [yearEndMonthIndex, setYearEndMonthIndex] = useState();
  const [segments, setSegments] = useState(DEFAULT_SEGMENTS);
  const [customerMetadataSegments, setCustomerMetadataSegments] = useState();
  const [transactionMetadataSegments, setTransactionMetadataSegments] = useState();
  const [localeRegion, setLocaleRegion] = useState();

  const [integrationObjectFields, setIntegrationObjectFields] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const orgId = organizations?.[0].id;
  const { data: integrationTypes } = useIntegrationsAPI({ orgId });
  const { updateConfigFromWizard, replaceAllMetadataSegments, updateConfig } = useConfigAPI({ orgId });
  const {
    analyticsIntegrations,
    hasActiveAnalyticsIntegrations,
    deactivateAllIntegrations,
    updateAnalyticsIntegration,
    updateAnalyticsIntegrations,
  } = useAnalyticsIntegrations({
    integrationTypes,
  });

  const orgConfig = useRef(orgConfigs);

  const data = {
    user: {
      orgId,
      company: organizations?.[0].name,
    },
    integrationTypes,
    integrationObjectFields,
  };

  const {
    segments: appSegments,
    customCustomerMetadataSegments,
    customTransactionMetadataSegments,
    locale_region: initalLocaleRegion,
  } = orgConfig?.current;

  useEffect(() => {
    const setInitialValues = async () => {
      setIsARR(appIsARR);
      setIsCommitted(appIsCommitted);
      setCommittedPolicy(appCommittedPolicy);
      setCommittedStart(appCommittedStart);

      if (appInfluxMonths) {
        setInfluxOption('influx');
        setInfluxMonths(appInfluxMonths);
      }

      if (appQuarters) {
        setYearEndMonthIndex(dayjs(appQuarters[appQuarters.length - 1], 'MMM').get('month'));
      }

      if (appSegments) {
        setSegments(
          Object.entries(appSegments.size).map(([key, value]) => {
            if (value.length === 1) {
              // largest segment is supposed to have just one entry, and upper bound should be Infinity
              return { name: key, amount: null };
            }
            // all other segments should have 2 entries, the later entry is the upper bound
            return { name: key, amount: value.length === 2 ? value[1] : null };
          }),
        );
      }

      if (customCustomerMetadataSegments) {
        setCustomerMetadataSegments(
          (customCustomerMetadataSegments || []).map((option) => ({
            key: option.key,
            type: option.bucket_type,
            levels: valuesToLevels(option),
            useZeroBucket: option.value['$useZeroBucket'],
          })),
        );
      }

      if (customTransactionMetadataSegments) {
        setTransactionMetadataSegments(
          (customTransactionMetadataSegments || []).map((option) => ({
            key: option.key,
            type: option.bucket_type,
            levels: valuesToLevels(option),
            useZeroBucket: option.value['$useZeroBucket'],
          })),
        );
      }

      if (initalLocaleRegion) {
        setLocaleRegion(initalLocaleRegion);
      }
    };

    if (organizations) {
      setInitialValues();
    }
    // eslint-disable-next-line
  }, [
    appCommittedPolicy,
    appCommittedStart,
    appInfluxMonths,
    organizations,
    appIsARR,
    appIsCommitted,
    appSegments,
    customCustomerMetadataSegments,
    customTransactionMetadataSegments,
  ]);

  useEffect(() => {
    const getExternalObjectFields = async () => {
      const integrationExternalObjectFields = await getIntegrationsObjectFields({ orgId });
      setIntegrationObjectFields(integrationExternalObjectFields);
      setIsLoading(false);
    };

    if (integrationTypes.length === 0) {
      setIsLoading(false);
    } else {
      getExternalObjectFields();
    }
  }, [integrationTypes, orgId]);

  const rawData = {
    // general settings
    isARR,
    isCommitted,
    influxMonths,
    yearEndMonthIndex,
    segments,
  };

  if (influxOption === 'influx') {
    rawData.influxMonths = influxMonths && influxMonths !== '' ? influxMonths : 0;
  }

  const saveConfigs = async () => {
    setIsLoading(true);

    if (initalLocaleRegion !== localeRegion) {
      await updateConfig({
        key: 'locale_region',
        value: localeRegion,
      });
    }

    const data = getConfigsFromData(rawData);

    if (currencyISOCode !== appCurrencyISOCode) {
      data.customSettings = {
        ...data.customSettings,
        currencyISOCode: currencyISOCode,
      };
    }

    await updateConfigFromWizard({
      data: {
        ...data,
        selectedIntegration: integrationTypes.find((integration) => integration.type === INTEGRATION_TYPES.CRM)
          ?.service,
      },
    });

    await appSetCurrencyISOCode(currencyISOCode);

    await replaceAllMetadataSegments.mutateAsync({
      customerMetadataSegments: formatMetadataSegments({
        rawMetadataSegments: customerMetadataSegments,
        objectType: METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER,
      }),
      transactionMetadataSegments: formatMetadataSegments({
        rawMetadataSegments: transactionMetadataSegments,
        objectType: METADATA_SEGMENT_OBJECT_TYPES.TRANSACTION,
      }),
    });

    await updateAnalyticsIntegrations();

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="w-100 flexer" style={{ marginTop: 20 }}>
        <Loader containerStyles={{ width: 40 }} />
      </div>
    );
  }

  return (
    <>
      <StyledHeaderIconButton icon={<SaveIcon />} iconFillColor="var(--primaryGreen)" onClick={() => saveConfigs()}>
        Save Settings
      </StyledHeaderIconButton>

      <BodyContainer>
        {error && <ConfigError>* {error}</ConfigError>}

        <SubscriptionData
          configPageView={true}
          isARR={isARR}
          setIsARR={setIsARR}
          isCommitted={isCommitted}
          setIsCommitted={setIsCommitted}
          committedPolicy={committedPolicy}
          setCommittedPolicy={setCommittedPolicy}
          committedStart={committedStart}
          setCommittedStart={setCommittedStart}
          influxMonths={influxMonths}
          setInfluxMonths={setInfluxMonths}
          influxOption={influxOption}
          setInfluxOption={setInfluxOption}
          yearEndMonthIndex={yearEndMonthIndex}
          setYearEndMonthIndex={setYearEndMonthIndex}
          segments={segments}
          customerMetadataSegments={customerMetadataSegments}
          setCustomerMetadataSegments={setCustomerMetadataSegments}
          transactionMetadataSegments={transactionMetadataSegments}
          setTransactionMetadataSegments={setTransactionMetadataSegments}
          setSegments={setSegments}
          currencyISOCode={currencyISOCode}
          setCurrencyISOCode={setCurrencyISOCode}
          {...data}
        />

        <Centerer>
          <NotificationsCard
            integrationTypes={integrationTypes}
            analyticsIntegrations={analyticsIntegrations}
            hasActiveAnalyticsIntegrations={hasActiveAnalyticsIntegrations}
            updateAnalyticsIntegration={updateAnalyticsIntegration}
            deactivateAllIntegrations={deactivateAllIntegrations}
          />
        </Centerer>

        <Centerer>
          <APIKeyCard />
        </Centerer>

        <Centerer>
          <LocaleRegionCard localeRegion={localeRegion} setLocaleRegion={setLocaleRegion} />
        </Centerer>

        <div style={{ height: 50 }}></div>

        <StyledIconButton
          data-cy="wizard__save-button"
          style={{ display: 'flex' }}
          filled
          border
          color={COLORS.GREEN}
          icon={<SaveIcon />}
          iconFillColor={'#FFF'}
          onClick={() => saveConfigs()}
        >
          Save Settings
        </StyledIconButton>
      </BodyContainer>
    </>
  );
};
