import dayjs from 'dayjs';
import { components, createFilter } from 'react-select';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { CustomDatePicker, FormikCustomCheckbox, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { SelectDropdownButton } from 'components/Buttons';
import { FlexerColumn, Spacer } from 'components/Core';
import {
  HeaderSelector,
  SelectorTitle,
  CustomOption,
  CustomOptionKey,
  CustomOptionValue,
  IdSourceSelectorWrapper,
} from 'shared/CsvUpload/styles';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';
import { AddMetadataDropdown } from './AddMetadataDropdown';
import { getTransactionsCellsValue } from './utils';
import {
  INTERNAL_TRANSACTION_ID_SOURCE,
  INTERNAL_CUSTOMER_ID_SOURCE,
  SOURCE_TYPES,
  NAME_MATCHING_SOURCE,
} from './consts';
import { renderCustomerIdCell, renderProductIdCell, renderRecognitionCell } from './fuzzyCells';

const { Option } = components;

export const CustomSingleOption = (props) => {
  // optimization for large lists
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  return (
    <Option {...props}>
      <CustomOption>
        <CustomOptionKey>{props.data.value}</CustomOptionKey>
        <CustomOptionValue>{props.data.label}</CustomOptionValue>
      </CustomOption>
    </Option>
  );
};

export const SelectDropdownWithTooltip = ({ name, selected, options, onSelect }) => (
  <SelectDropdownButton
    name={name}
    noMargin
    selected={selected}
    options={options}
    fontSize="12px"
    onSelect={onSelect}
    toolTipContent="Click to change type"
    toolTipWidth="125px"
  />
);

export const getColumns = ({
  objects,
  organization,
  formRef,
  csvColumns,
  csvColumnsMapper,
  setCsvColumnsMapper,
  currency,
  selectedMetadataFields,
  setSelectedMetadataFields,

  createOrUpdateMode,

  customers,
  customersSelectOptions,
  customersById,
  customersIdSources,
  customerIdSourceAndType,
  setCustomerIdSourceAndType,
  uploadCsvCustomersIdSource,

  transactions,
  transactionsSelectOptions,
  transactionsIdSources,
  transactionsIdSourceAndType,
  setTransactionsIdSourceAndType,

  fuzzyRowsMapper,
  setFuzzyRowsMapper,

  defaultMapper,
}) => {
  const toggleSelectedMetadataField = (field) => {
    if (!selectedMetadataFields.includes(field)) {
      setSelectedMetadataFields([...selectedMetadataFields, field]);
    } else {
      setSelectedMetadataFields(selectedMetadataFields.filter((f) => f !== field));
    }
  };

  const handleCustomerIdSourceChange = (newValue) => {
    setCsvColumnsMapper(defaultMapper);

    setCustomerIdSourceAndType({
      type: [uploadCsvCustomersIdSource, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(newValue)
        ? SOURCE_TYPES.INTERNAL
        : SOURCE_TYPES.EXTERNAL,
      source: newValue,
    });
  };

  return [
    {
      Header: () => (
        <FlexerColumn>
          <HeaderSelector>
            <SelectorTitle>Metadata</SelectorTitle>
          </HeaderSelector>
          <AddMetadataDropdown
            selectedMetadataFields={selectedMetadataFields}
            toggleSelectedMetadataField={toggleSelectedMetadataField}
            objects={objects}
          />
        </FlexerColumn>
      ),
      width: 80,
      accessor: 'status',
      Cell: () => <></>,
    },
    ...(selectedMetadataFields?.map((metadataField) => {
      const fieldQualifier = `metadata.${metadataField}`;

      return {
        Header: () => (
          <HeaderSelector textTransform="none">
            <SelectorTitle>Metadata: {metadataField}</SelectorTitle>
            <ColumnMapperSelector
              field={fieldQualifier}
              csvColumnsMapper={csvColumnsMapper}
              setCsvColumnsMapper={setCsvColumnsMapper}
              csvColumns={csvColumns}
            />
          </HeaderSelector>
        ),
        width: 180,
        accessor: fieldQualifier,
        Cell: ({ row }) => {
          return (
            <FormikCustomInput
              style={{ height: 32 }}
              errorWithoutTooltip
              placeholder="Enter value..."
              width="100%"
              name={`[${row.original.rowIndex}].${fieldQualifier}`}
            />
          );
        },
      };
    }) ?? []),
    ...(createOrUpdateMode === CSV_MODES.UPDATE
      ? [
          {
            Header: () => (
              <IdSourceSelectorWrapper>
                <SelectDropdownWithTooltip
                  name="transactions-ids-source"
                  selected={transactionsIdSourceAndType?.source}
                  options={transactionsIdSources}
                  onSelect={(source) => {
                    setCsvColumnsMapper(defaultMapper);

                    setTransactionsIdSourceAndType({
                      type: source === INTERNAL_TRANSACTION_ID_SOURCE ? SOURCE_TYPES.INTERNAL : SOURCE_TYPES.EXTERNAL,
                      source,
                    });
                  }}
                />
                <Spacer height="6px" />
                <ColumnMapperSelector
                  id="csv-column-with-source"
                  field="transaction_id"
                  csvColumnsMapper={csvColumnsMapper}
                  setCsvColumnsMapper={setCsvColumnsMapper}
                  csvColumns={csvColumns}
                />
              </IdSourceSelectorWrapper>
            ),
            width: 180,
            accessor: 'transaction_id',
            Cell: ({ row, cell: { value } }) => (
              <FormikCustomSelector
                isClearable={true}
                height={32}
                components={{ Option: CustomSingleOption }}
                placeholder="Select id..."
                minWidth={160}
                menuWidth={200}
                filterOption={createFilter({ ignoreAccents: false })}
                errorWithoutTooltip
                value={value ? { value: value, label: value } : null}
                options={transactionsSelectOptions}
                noSort
                handleChange={(option) => {
                  if (option) {
                    formRef?.current?.setFieldValue(`[${row.original.rowIndex}].transaction_id`, option.value);

                    getTransactionsCellsValue({
                      products: organization?.products,
                      customers,
                      transactions,
                      formRefCurrent: formRef.current,
                      transactionId: option.value,
                      rowIndex: row.original.rowIndex,
                    });
                  } else {
                    formRef?.current?.setFieldValue(`[${row.original.rowIndex}].transaction_id`, null);
                  }
                }}
                name={`[${row.original.rowIndex}].transaction_id`}
              />
            ),
          },
        ]
      : []),
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Date</SelectorTitle>
          <ColumnMapperSelector
            field="date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].date`)}
          width="100%"
          name={`[${row.original.rowIndex}].date`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Deal name (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter name..."
          width="100%"
          name={`[${row.original.rowIndex}].name`}
        />
      ),
    },
    {
      Header: () => (
        <IdSourceSelectorWrapper>
          <SelectDropdownWithTooltip
            name="customers-ids-source"
            selected={customerIdSourceAndType?.source}
            options={customersIdSources}
            onSelect={handleCustomerIdSourceChange}
          />
          <Spacer height="6px" />
          <ColumnMapperSelector
            field="customer_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </IdSourceSelectorWrapper>
      ),
      width: 180,
      accessor: 'customer_id',
      Cell: (cellProps) =>
        renderCustomerIdCell({
          ...cellProps,
          customerIdSourceAndType,
          customers,
          customersById,
          customersSelectOptions,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
          uploadCsvCustomersIdSource,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Amount</SelectorTitle>

          <ColumnMapperSelector
            field="amount"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'amount',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
          errorWithoutTooltip
          style={{ height: 32 }}
          placeholder="Enter amount..."
          type="number"
          width="100%"
          name={`[${row.original.rowIndex}].amount`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>MRR (Optional)</SelectorTitle>

          <ColumnMapperSelector
            field="recurring_amount"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'recurring_amount',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
          errorWithoutTooltip
          style={{ height: 32 }}
          placeholder="Enter MRR..."
          type="number"
          width="100%"
          name={`[${row.original.rowIndex}].recurring_amount`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Product</SelectorTitle>

          <ColumnMapperSelector
            field="product_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'product_id',
      width: 180,
      Cell: (cellProps) =>
        renderProductIdCell({
          ...cellProps,
          organization,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
        }),
    },
    {
      Header: () => (
        <HeaderSelector data-cy="upload-csv__header-selector--start_date">
          <SelectorTitle>Start Date</SelectorTitle>
          <ColumnMapperSelector
            field="start_date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'start_date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].start_date`)}
          width="100%"
          name={`[${row.original.rowIndex}].start_date`}
        />
      ),
    },

    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>End Date</SelectorTitle>

          <ColumnMapperSelector
            field="end_date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'end_date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].end_date`)}
          width="100%"
          name={`[${row.original.rowIndex}].end_date`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Recognition (optional)</SelectorTitle>

          <ColumnMapperSelector
            field="recognition"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'recognition',
      width: 180,
      Cell: (cellProps) =>
        renderRecognitionCell({
          ...cellProps,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Seats (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="seats"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'seats',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          type="number"
          placeholder="Enter seats..."
          width="100%"
          name={`[${row.original.rowIndex}].seats`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Include end month (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="include_end_month"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'include_end_month',
      width: 180,
      Cell: ({ row }) => <FormikCustomCheckbox name={`[${row.original.rowIndex}].include_end_month`} />,
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Currency (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="currency"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'currency',
      width: 160,
      Cell: ({ row }) => (
        <FormikCustomSelector
          height={32}
          minWidth={160}
          menuWidth={200}
          //optimization thing
          filterOption={createFilter({ ignoreAccents: false })}
          errorWithoutTooltip
          placeholder="Select currency..."
          options={Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => ({
            label: `${symbol} (${code})`,
            value: code,
          }))}
          name={`[${row.original.rowIndex}].currency`}
          handleChange={(option) => {
            formRef?.current?.setFieldValue(`[${row.original.rowIndex}].currency`, option.value);
          }}
        />
      ),
    },
  ];
};
