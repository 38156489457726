import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { cssVar } from 'polished';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { usePrevious } from 'utils/hooks/usePrevious';
import { Header, HeaderTitle, HEADER_TITLES } from 'shared/Layout';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { IconButton, LinkBackButton } from 'components/Buttons';
import { CheckIcon } from 'components/Icons';
import { Row, Spacer } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { COLORS } from 'consts/colors';
import { ImportForm } from './ImportForm';
import { CsvUploader } from './CSVReaderUploader';
import { mapFromParsedCsv, updateColumnData } from './utils';
import { HelpModal } from './HelpModal';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding-left: 20px;
`;

export const CSV_MODES = {
  CREATE: 'create',
  UPDATE: 'update',
};

export const CsvUpload = ({
  entityName,
  defaultMapperWithCreateOrUpdateMode,
  onlyCreateMode,
  isLoading,
  getColumnsWithCsvUploadState,
  csvColumnsTransformations = {},
  handleSubmit,
  createSchema,
  updateSchema,
  selectedMetadataFields,
  backLink,
  showViewModes,
  additionalInfoText,
}) => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const { organizations, dateFormat } = useContext(AppContext);

  //  Example: { 1: {customer_id: { confidence, ... }, product_id: {confidence, ...} },
  const [fuzzyRowsMapper, setFuzzyRowsMapper] = useState({});
  const [createOrUpdateMode, setCreateOrUpdateMode] = useState(onlyCreateMode ? CSV_MODES.CREATE : undefined);

  const initialMapper = defaultMapperWithCreateOrUpdateMode({ createOrUpdateMode });
  const [csvColumnsMapper, setCsvColumnsMapper] = useState({});
  const prevCsvColumnsMapper = usePrevious(csvColumnsMapper);

  const [parsedCsv, setParsedCsv] = useState();

  const [mappedData, setMappedData] = useState();
  const [formIsValid, setFormIsValid] = useState();

  useEffect(() => {
    if (initialMapper) {
      setCsvColumnsMapper(initialMapper);
    }
  }, [initialMapper]);

  const formRef = useRef();

  useEffect(() => {
    //clean fuzzy mapper after new data loaded
    setFuzzyRowsMapper({});
  }, [isLoading]);

  // Here we update the form when the user changes data source for csv column
  useEffect(() => {
    const changedColumnMapping = Object.keys(csvColumnsMapper).filter((key) => {
      return csvColumnsMapper?.[key] !== prevCsvColumnsMapper?.[key];
    });

    if (parsedCsv && !!Object?.keys(csvColumnsMapper ?? {})?.length) {
      if (!mappedData) {
        setMappedData([
          ...mapFromParsedCsv({
            parsedCsv,
            csvColumnsMapper,
            prevCsvColumnsMapper,
            csvColumnsTransformations,
            allProducts: organizations[0]?.products,
            dateFormat,
          }),
        ]);
      } else if (changedColumnMapping?.length === 1) {
        // update data in column
        updateColumnData({
          changedColumnMapping,
          setFuzzyRowsMapper,
          parsedCsv,
          csvColumnsMapper,
          formRef,
          csvColumnsTransformations,
          allProducts: organizations[0]?.products,
          dateFormat,
        });
      }
    }

    // eslint-disable-next-line
  }, [csvColumnsMapper, setFuzzyRowsMapper, parsedCsv, organizations, selectedMetadataFields]);

  return (
    <Fragment>
      <Wrapper>
        <Header
          activePage={entityName}
          headerLeft={
            <>
              <LinkBackButton to={backLink ?? `/${entityName}`}>All {capitalize(entityName)}</LinkBackButton>
              <HeaderTitle>{HEADER_TITLES['csv']}</HeaderTitle>
            </>
          }
          headerRight={
            <>
              <IconButton
                icon={<HowWorksIcon />}
                iconFillColor={cssVar('--primaryGreen')}
                onClick={() => setShowHelpModal(true)}
              >
                Help
              </IconButton>

              <Spacer width="8px" />

              <IconButton
                iconFillColor={cssVar('--primaryGreen')}
                icon={<TableExportIcon />}
                onClick={() =>
                  (window.location.href = `${process.env.PUBLIC_URL}/templates/${capitalize(entityName)}_Template.csv`)
                }
              >
                Template
              </IconButton>

              <Spacer width="8px" />

              <IconButton
                disabled={!parsedCsv || !formIsValid || isLoading}
                filled
                border
                color={COLORS.GREEN}
                icon={<CheckIcon />}
                iconFillColor={'#FFF'}
                onClick={() => formRef?.current?.submitForm()}
              >
                Finish Uploading
              </IconButton>
            </>
          }
        />
        <Row>
          <CsvUploader
            additionalInfoText={additionalInfoText}
            onlyCreateMode={onlyCreateMode}
            createOrUpdateMode={createOrUpdateMode}
            setCreateOrUpdateMode={setCreateOrUpdateMode}
            smallView={parsedCsv}
            setParsedCsv={setParsedCsv}
            entityName={entityName}
          />
        </Row>
        {parsedCsv && (
          <TimeLoaderContainer isLoading={isLoading}>
            {isLoading ? (
              <TimeLoader pageName={`${entityName}Csv`} />
            ) : (
              <ImportForm
                entityName={entityName}
                formRef={formRef}
                parsedCsv={parsedCsv}
                setFormIsValid={setFormIsValid}
                setCsvColumnsMapper={setCsvColumnsMapper}
                csvColumnsMapper={csvColumnsMapper}
                mappedData={mappedData}
                createOrUpdateMode={createOrUpdateMode}
                schema={
                  createOrUpdateMode === CSV_MODES.UPDATE
                    ? updateSchema
                    : createOrUpdateMode === CSV_MODES.CREATE
                    ? createSchema
                    : undefined
                }
                defaultMapper={initialMapper}
                fuzzyRowsMapper={fuzzyRowsMapper}
                setFuzzyRowsMapper={setFuzzyRowsMapper}
                handleSubmit={handleSubmit}
                getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
                showViewModes={showViewModes}
              />
            )}
          </TimeLoaderContainer>
        )}
      </Wrapper>

      {showHelpModal ? <HelpModal onClose={() => setShowHelpModal(false)} /> : null}
    </Fragment>
  );
};
