import { FlexerColumn } from 'components/Core';
import React from 'react';
import styled from 'styled-components';

const CustomerName = styled.span`
  color: var(--primaryBlue);
  font-weight: 700;
  cursor: pointer;
`;

export const getColumns = ({ history }) => [
  {
    Header: 'CUSTOMER NAME',
    accessor: 'customer_name',
    id: 'customer_name',
    width: 200,
    Cell: ({ value, row }) => (
      <CustomerName onClick={() => history.push(`/contracts/${row.original.id}`)}>
        {value || '[No customer name]'}
      </CustomerName>
    ),
  },
  {
    Header: 'TRANSACTION NAMES',
    accessor: 'transactions',
    id: 'transactions',
    width: 300,
    Cell: ({ value }) =>
      value?.length > 0 ? (
        <FlexerColumn gap="4px">
          {value?.map(({ name }) => (
            <span key={name}>• {name || '[No transaction name]'}</span>
          ))}
        </FlexerColumn>
      ) : (
        '[No Transactions]'
      ),
  },
  {
    Header: 'ATTACHMENTS',
    accessor: 'attachments',
    id: 'attachments',
    width: 300,
    Cell: ({ value }) =>
      value?.length > 0 ? (
        <FlexerColumn gap="4px">
          {value?.map(({ name }) => (
            <span key={name}>• {name || '[No attachment name]'}</span>
          ))}
        </FlexerColumn>
      ) : (
        '[No Attachments]'
      ),
  },
];
