import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArchiveIcon } from 'images/actions-dropdown__archive.svg';
import { PlusSignIcon } from 'components/Icons';
import { ActionsPopover } from 'shared/Common';
import { FlexerRow, Row } from 'components/Core';
import { IconButton, LinkBackButton } from 'components/Buttons';

const CustomerName = styled.div`
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
`;

export const ContractSinglePageHeader = ({ contractData, archiveContract }) => (
  <Row horizontal="space-between" style={{ margin: '20px 0' }}>
    <Row horizontal="start" gap="8px">
      <LinkBackButton data-cy="page-header-back-button" to="/contracts">
        Back
      </LinkBackButton>

      <CustomerName>{`Contract for ${contractData?.customer_name ?? '[No customer name]'}`}</CustomerName>
    </Row>

    <FlexerRow justifyContent="end" gap="12px">
      <IconButton
        data-cy="contract-page__add-transactions"
        icon={<PlusSignIcon />}
        iconFillColor="var(--primaryGreen)"
        onClick={() => null} // TODO
      >
        Transaction
      </IconButton>

      <ActionsPopover
        showHoverText={false}
        actions={{
          Remove: {
            icon: <ArchiveIcon />,
            cb: () => archiveContract({ id: contractData?.id }),
          },
        }}
      />
    </FlexerRow>
  </Row>
);
