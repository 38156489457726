import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { useCustomerAPI } from 'api/customers';
import { AppContext } from 'AppContext';
import { Column, Flexer, FlexerRow, Row, Spacer } from 'components/Core';
import { Loader } from 'components/Loaders';
import { RATES_TYPE } from 'consts/global';
import { useInvariantCheck } from 'utils/hooks';

import { CustomerInfoCards } from './CustomerInfoCards';
import { CustomerTablesTabs } from './CustomerTablesTabs';
import { CustomerCharts } from './CustomerCharts';
import { CustomerMetadata } from './CustomerMetadata';
import { CustomerImportedButton } from './CustomerImportedButton';
import { checkTotalRevenue } from './invariantChecks';
import { CustomerViewContentContainer } from '../styles';
import { CustomerBillingAddress } from './CustomerBillingAddress';

const TopPanelWrapper = styled(FlexerRow)`
  padding-inline: 20px;
  max-height: 675px;
  overflow: auto;
  padding-bottom: 10px;
`;

const CustomerDetailWrapper = styled.div`
  padding: 0 40px 40px;
  box-shadow: 2px 20px 48px rgba(0, 21, 46, 0.028);
  border-radius: 8px;
  flex-grow: 1;
  margin-right: 22px;
  min-height: 100%;
  height: fit-content;
`;

const ExtraBoldText = styled.b`
  font-weight: 900;
  color: ${({ light }) => (light ? 'var(--primaryBlack50)' : 'var(--primaryBlack)')};
`;

export const CustomerViewContent = ({ id, onCustomerFetched }) => {
  const {
    organizations,
    appSettings: { isCommitted, rollup, exchangeRatesType, exchangeRatesDate, countInfluxAsRenewed },
  } = useContext(AppContext);

  const [customer, setCustomer] = useState();

  const params = {
    scopes: ['transactions', 'imports', 'timeseries_metrics', 'invoicing_details'],
    asOf: dayjs().startOf('day').toISOString(),
    includeHierarchy: !!rollup,
    isCommitted,
    rollup,
    countInfluxAsRenewed,
    ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
    ratesDate: exchangeRatesDate,
  };

  const { data, isLoading } = useCustomerAPI({
    orgId: organizations?.[0]?.id,
    customerId: id,
    filters: params,
    enabled: !!id,
  });

  const paramsWithMRRScopes = {
    ...params,
    // The order of these scopes matters
    scopes: [
      'current_and_previous_MRR_and_customer_lifetime',
      'transactions_sum',
      'customer_monthly_MRR',
      'transactions',
      'imports',
      'timeseries_metrics',
    ],
  };
  const {
    data: customerDataWithMRR,
    isLoading: isLoadingWithMRR,
    operations: { refetch },
  } = useCustomerAPI({
    orgId: organizations?.[0]?.id,
    customerId: id,
    filters: paramsWithMRRScopes,
    enabled: !!id,
  });

  useEffect(() => {
    onCustomerFetched?.(customerDataWithMRR ?? data);
    setCustomer(customerDataWithMRR ?? data);
  }, [data, customerDataWithMRR, onCustomerFetched]);

  const fetchCustomer = refetch;

  useInvariantCheck({
    readyData: !isLoadingWithMRR && data ? data : null,
    checkers: [checkTotalRevenue],
    checkerContext: {
      rollup,
    },
    deps: [rollup],
  });

  return (isLoading && isLoadingWithMRR) || !customer ? (
    <div className="w-100 flexer">
      <Loader containerStyles={{ width: 40 }} />
    </div>
  ) : (
    <CustomerViewContentContainer>
      <TopPanelWrapper gap="10px">
        <CustomerMetadata customer={customer} fetchCustomer={fetchCustomer} organizationId={organizations[0].id} />

        <CustomerDetailWrapper>
          <CustomerInfoCards customer={customer} />

          <Row vertical="stretch">
            <CustomerCharts customer={customer} />
          </Row>
        </CustomerDetailWrapper>
      </TopPanelWrapper>

      <Column horizontal="flex-start" style={{ height: 'initial', width: '100%', padding: '40px' }}>
        <ExtraBoldText>
          Billing Address <ExtraBoldText light>in Subscript</ExtraBoldText>
        </ExtraBoldText>
        <Spacer height="8px" />
        <CustomerBillingAddress
          customer={customer}
          fetchCustomer={fetchCustomer}
          organizationId={organizations[0].id}
        />
        <Spacer height="10px" />
        <Flexer wrapRow gap="8px">
          <CustomerImportedButton customer={customer} />
        </Flexer>
      </Column>

      <CustomerTablesTabs fetchCustomer={fetchCustomer} customer={customer} />
    </CustomerViewContentContainer>
  );
};
