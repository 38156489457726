import React, { useContext } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserAPI } from 'api/users';
import { initiateIntegrationLogin } from 'api/integrations';
import { OAUTH_CALLBACK_PATH } from 'views/Wizard/consts';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import {
  BannersContainer,
  Banner,
  BannerText,
  BannerActions,
  BannerButton,
  BannerTitle,
} from 'components/Blocks/Banner';
import { BillingContext } from '../BillingContext';

const Wrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const DisconnectedIntegrationBanner = ({ showOnlyForIntegrationId }) => {
  const { glDisconnectedIntegrations } = useContext(BillingContext);
  const { user: authUser = {} } = useAuth0();
  const { data: userData } = useUserAPI({ email: authUser.email });

  if (glDisconnectedIntegrations.length === 0) {
    return <></>;
  }

  const disconnectedIntegrations = showOnlyForIntegrationId
    ? glDisconnectedIntegrations.filter((integration) => integration.id === showOnlyForIntegrationId)
    : glDisconnectedIntegrations;

  return (
    <>
      {disconnectedIntegrations.map((integration) => (
        <Wrapper data-cy={`disconnected-integration-banner-${integration.id}`} key={integration.id}>
          <div onClick={() => initiateIntegrationLogin(userData.id, integration.service, OAUTH_CALLBACK_PATH.CONFIGS)}>
            <BannersContainer redVersion>
              <Banner redVersion>
                <BannerTitle redVersion>{`Your ${getIntegrationDisplayName(
                  integration,
                )} integration is disconnected`}</BannerTitle>
                <BannerText>
                  <div>{`Subscript can't process invoices in your general ledger. Please visit ${getServiceCategory(
                    integration.service,
                  )} and re-connect the integration for invoicing.`}</div>
                </BannerText>
                <BannerActions>
                  <BannerButton>{`Reintegrate ${getServiceCategory(integration.service)}`}</BannerButton>
                </BannerActions>
              </Banner>
            </BannersContainer>
          </div>
        </Wrapper>
      ))}
    </>
  );
};
