import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { createCustomer } from 'api';
import { getCustomerDisplayName } from 'models/customer';

import { ReactComponent as DeleteIcon } from 'images/trash.svg';
import { Centerer, Row } from 'components/Core';
import { ExternalLinkFullIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { FormikCustomSelector } from 'components/Controls';
import { getCustomersFromSearch } from 'shared/TransactionContent';

import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { InvoicingScheduleDeleteModal } from './InvoicingScheduleDeleteModal';
import { CustomerExternalSelector } from '../Common/CustomerExternalSelector';
import { DEFAULT_INVOICE_LANGUAGE, MANUAL_GL_SERVICE_NAME } from '../consts';
import { DisconnectedIntegrationBanner } from '../Common/DisconnectedIntegrationBanner';

const Wrapper = styled.div`
  position: relative;
  padding: 20px 40px 24px 40px;

  @media (max-width: 1439px) {
    padding: 20px 60px 24px 40px;
  }
`;

export const Heading = styled.h2`
  margin-bottom: 0;
  font-weight: 900;
  white-space: nowrap;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: var(--primaryBlack);
`;

const SelectorWrapper = styled.div`
  position: relative;
  margin-left: ${({ hasValue }) => (hasValue ? 0 : '12px')};
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  .react-select__single-value--is-disabled {
    max-width: 250px;
    color: var(--accentDarkGray);
  }
`;

const ExternalLinkBox = styled(Centerer)`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const DeleteIconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  background: white;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  cursor: pointer;

  &:hover {
    box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
  }
`;

export const InvoicingScheduleName = styled.span`
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
`;

export const InvoicingScheduleHeader = () => {
  const {
    invoicingScheduleFormValues,
    orgId,
    glIntegration,
    showDeleteModal,
    setShowDeleteModal,
    setCustomer,
    preselectedCustomer,
    currentInvoicingSchedule,
    setIncludedTransactions,
    fetchedSelectedInvoice: invoice,
    invoicingService,
    refetchCustomer,
    invoicingServiceDisplayName,
  } = useContext(InvoicingScheduleContext);

  const { setFieldValue } = useFormikContext();

  return (
    <Wrapper>
      <Row horizontal="start" gap="16px">
        <Heading>
          Invoicing Schedule for:
          <SelectorWrapper
            data-cy="invoicing-schedule-customer-selector"
            hasValue={invoicingScheduleFormValues?.customer_id}
          >
            <FormikCustomSelector
              key={invoicingScheduleFormValues?.customer_id}
              redVer={true}
              placeholder="Select a customer..."
              value={
                invoicingScheduleFormValues?.customer_id
                  ? {
                      label: getCustomerDisplayName({
                        customerName: invoicingScheduleFormValues?.customer_name,
                        customerId: invoicingScheduleFormValues?.customer_id,
                        customerEmail: invoicingScheduleFormValues?.customer_email,
                      }),
                      value: invoicingScheduleFormValues?.customer_id,
                    }
                  : null
              }
              name="customer_id"
              handleChange={(option) => {
                if (option) {
                  setFieldValue('customer_id', option.value);
                  setFieldValue('customer_name', option.label);
                  setFieldValue('language', option.metadata?.invoice_language ?? DEFAULT_INVOICE_LANGUAGE);
                  setCustomer({ id: option.value, name: option.label });
                } else {
                  setFieldValue('customer_id', null);
                  setFieldValue('customer_name', null);
                  setFieldValue('language', DEFAULT_INVOICE_LANGUAGE);
                  setCustomer(null);
                }

                setFieldValue('invoices', []);
                setFieldValue('invoicing_frequency', null);
                setIncludedTransactions([]);
              }}
              onCreateOption={async (newCustomerName) => {
                const newCustomer = await createCustomer({
                  orgId,
                  customerName: newCustomerName,
                });
                setFieldValue('customer_id', newCustomer.id);
                setFieldValue('customer_name', newCustomer.name);

                setFieldValue('invoices', []);
                setFieldValue('invoicing_frequency', null);
                setIncludedTransactions([]);
              }}
              loadOptions={(searchQuery, prevOptions, additional) =>
                getCustomersFromSearch({
                  searchQuery,
                  withoutProspects: true,
                  orgId,
                  prevOptions,
                  additional,
                })
              }
              isDisabled={preselectedCustomer}
              isPaginateable
              isClearable
              creatable
              showErrors={false}
            />
          </SelectorWrapper>
          {invoicingScheduleFormValues?.customer_id && (
            <TooltipContainer width={140} fontSize="12px" toolTipContent="Customer in Subscript" hideArrow>
              <ExternalLinkBox
                onClick={() => window.open(`/customers/${invoicingScheduleFormValues?.customer_id}`, '_blank')}
              >
                <ExternalLinkFullIcon />
              </ExternalLinkBox>
            </TooltipContainer>
          )}
        </Heading>

        {invoicingScheduleFormValues?.customer_id && invoicingService !== MANUAL_GL_SERVICE_NAME && (
          <CustomerExternalSelector
            customerId={invoicingScheduleFormValues?.customer_id}
            invoice={invoice}
            invoicingService={invoicingService}
            onModalClose={refetchCustomer}
            invoicingServiceDisplayName={invoicingServiceDisplayName}
            integrationId={invoicingScheduleFormValues?.integration_id}
          />
        )}

        {currentInvoicingSchedule?.id && (
          <TooltipContainer
            width={200}
            fontSize="12px"
            toolTipContent="Delete the schedule and all its invoices"
            hideArrow
          >
            <DeleteIconWrapper data-cy="invoicing-schedule__remove-schedule" onClick={() => setShowDeleteModal(true)}>
              <DeleteIcon width={20} height={20} />
            </DeleteIconWrapper>
          </TooltipContainer>
        )}
      </Row>

      <DisconnectedIntegrationBanner showOnlyForIntegrationId={glIntegration?.id} />

      {showDeleteModal && <InvoicingScheduleDeleteModal />}
    </Wrapper>
  );
};
