import { cssVar } from 'polished';

import { Base, BaseNoHeader } from 'shared/Layout';
import { CohortsContainer } from 'views/Cohorts';
import { Configuration } from 'views/Configuration';
import { CustomersRouter } from 'views/Customers';
import { ReportsRouter } from 'views/Reports';
import { DASHBOARD_PAGES_ROUTES, ReportBuilderContainer } from 'views/Dashboard/ReportBuilder';
import { WaterfallContainer } from 'views/Waterfall';
import Spreads from 'views/Spreads/Spreads';
import RevenueDetails from 'views/RevenueDetails/RevenueDetails';
import { TransactionsContainer, TransactionSinglePage, TRANSACTIONS_PAGES_ROUTES } from 'views/Transactions';
import { FORECASTING_PAGES_ROUTES, ForecastingContainer } from 'views/Forecasting';
import { ExternalUpdatesPage } from 'views/ExternalUpdates';
import { ChartVisualizerContainer } from 'views/ChartVisualizer';
import { BillingContainer } from 'views/Billing';
import { ContractSinglePage } from 'views/Contracts';
import { ReviewTransactionsPage } from 'views/Billing/Pages/ReviewTransactions/ReviewTransactionsPage';
import { UsageBasedEnginePage } from 'views/Billing/Pages/UsageBasedEngine/UsageBasedEnginePage';

import { TEST_Playground } from 'views/TEST_Playground/TEST_Playground';
import { BILLING_PAGES_ROUTES } from 'views/Billing/consts';
import { TransactionsCsvUpload } from 'views/Transactions/TransactionsCsvUpload/TransactionsCsvUpload';
import { CustomersCsvUpload } from 'views/Customers/CustomersCsvUpload/CustomersCsvUpload';
import { RatesCsvUpload } from 'views/Configuration/Tabs/ExchangeRatesTab/RatesCsvUpload/RatesCsvUpload';
import { UsageEventsCsvUpload } from 'views/Billing/UsageBasedEngine/UsageEventsCsvUpload/UsageEventsCsvUpload';
import { EventBasedSpreadsCsvUpload } from 'views/Transactions/EventBasedSpreadsCsvUpload/EventBasedSpreadsCsvUpload';
import { AccountingSpreadsCsvUpload } from 'views/Transactions/accountingSpreadsCsvUpload/AccountingSpreadsCsvUpload';
import { BillingInconsistenciesReportPage } from 'views/Billing/Pages/BillingInconsistenciesReport/BillingInconsistenciesReportPage';
import { ProductsCsvUpload } from 'views/Configuration/Tabs/ProductsTab/ProductsCsvUpload/ProductsCsvUpload';
import { RevRecExceptionsReportPage } from 'views/Billing/Pages/RevRecExceptionsReport/RevRecExceptionsReportPage';
import { SubscriptionsCsvUpload } from 'views/Billing/UsageBasedEngine/SubscriptionsCsvUpload/SubscriptionsCsvUpload';
import { PricingPlansCsvUpload } from 'views/Billing/UsageBasedEngine/PricingPlansCsvUpload/PricingPlansCsvUpload';
import { ContractsPdfsContainer } from 'views/Contracts/ContractsListView/ContractsPdfsContainer';
import { ContractsListContainer } from 'views/Contracts/ContractsListView/ContractsList/ContractsListContainer';

const routes = [
  {
    path: '/test-playground',
    exact: true,
    layout: Base,
    layoutProps: {
      active: 'dashboard',
    },
    view: TEST_Playground,
  },
  ...Object.values(DASHBOARD_PAGES_ROUTES).map((path) => ({
    path,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'dashboard',
    },
    view: ReportBuilderContainer,
  })),
  {
    path: '/waterfall',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'waterfall',
    },
    view: WaterfallContainer,
  },
  {
    path: '/cohorts',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'cohorts',
    },
    view: CohortsContainer,
  },
  {
    path: BILLING_PAGES_ROUTES.REVIEW_TRANSACTIONS,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      backgroundColor: cssVar('--primaryGray'),
      noPaddings: true,
    },
    view: ReviewTransactionsPage,
  },
  {
    path: BILLING_PAGES_ROUTES.USAGE_BASED_ENGINE,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: UsageBasedEnginePage,
  },
  {
    path: '/billing',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingContainer,
  },
  {
    path: BILLING_PAGES_ROUTES.DASHBOARD,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingContainer,
  },
  {
    path: BILLING_PAGES_ROUTES.INVOICES,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingContainer,
  },
  {
    path: `${BILLING_PAGES_ROUTES.INVOICES}/:invoiceId`,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingContainer,
  },
  {
    path: '/billing-inconsistencies-report',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingInconsistenciesReportPage,
  },
  {
    path: '/rev-rec-exceptions-report',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: RevRecExceptionsReportPage,
  },
  {
    path: BILLING_PAGES_ROUTES.SCHEDULES_EXTERNAL_UPDATES,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingContainer,
  },
  {
    path: BILLING_PAGES_ROUTES.AR_AGING_REPORT,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noPaddings: true,
    },
    view: BillingContainer,
  },
  {
    path: FORECASTING_PAGES_ROUTES.FORECASTING,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'forecasting',
    },
    view: ForecastingContainer,
  },
  {
    path: TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: TransactionsContainer,
  },
  {
    path: '/contracts/pdfs',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: ContractsPdfsContainer,
  },
  {
    path: '/contracts',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: ContractsListContainer,
  },
  {
    path: '/contracts/:contractId',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: ContractSinglePage,
  },
  {
    path: '/csv/transactions',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: TransactionsCsvUpload,
  },
  {
    path: '/csv/spreads',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: EventBasedSpreadsCsvUpload,
  },
  {
    path: '/csv/accountingSpreads',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: AccountingSpreadsCsvUpload,
  },
  {
    path: '/csv/customers',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'customers',
      noBorder: true,
    },
    view: CustomersCsvUpload,
  },
  {
    path: '/csv/products',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'configure',
      noBorder: true,
    },
    view: ProductsCsvUpload,
  },
  {
    path: TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_UPDATES,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
    },
    view: ExternalUpdatesPage,
  },
  {
    path: TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_AUTOMATIC_CONFIRMATIONS,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: TransactionsContainer,
  },
  {
    path: TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_ARCHIVED,
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noBorder: true,
    },
    view: TransactionsContainer,
  },
  {
    path: '/transactions/:id',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'transactions',
      noPaddings: true,
      noFooter: true,
    },
    view: TransactionSinglePage,
  },
  {
    path: '/revenue',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'revenue',
      noPaddings: true,
      noBorder: true,
    },
    view: RevenueDetails,
  },
  {
    path: '/spreads',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'spreads',
    },
    view: Spreads,
  },
  {
    path: '/customers',
    exact: false,
    view: CustomersRouter,
  },
  {
    path: '/configure',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'configure',
      noBorder: true,
    },
    view: Configuration,
  },
  {
    path: '/csv/rates',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'configure',
      noBorder: true,
    },
    view: RatesCsvUpload,
  },
  {
    path: '/csv/usage-events',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noBorder: true,
    },
    view: UsageEventsCsvUpload,
  },
  {
    path: '/csv/subscriptions',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noBorder: true,
    },
    view: SubscriptionsCsvUpload,
  },
  {
    path: '/csv/pricing-plans',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'billing',
      noBorder: true,
    },
    view: PricingPlansCsvUpload,
  },
  {
    path: '/chart',
    exact: true,
    layout: BaseNoHeader,
    layoutProps: {
      active: 'chart',
    },
    view: ChartVisualizerContainer,
  },
  {
    path: '/reports',
    exact: false,
    view: ReportsRouter,
  },
];
export default routes;
